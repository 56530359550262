import { MachineType } from '../../../api/machines/machines.types';
import { MachinesSortingField } from '../../../api/machines/routes/fetch-paginated-machines/fetch-paginated-machines.types';
import { MachineStatus, machineStatuses } from '../../../constants/machines';
import { MachineIssueDifficultyWithMultiple } from '../../../constants/options';
import { MachineRepairIssueType } from '../../../constants/options/options.types';
import {
  getSearchFilterAtomFamily,
  getSortByFilterAtomFamily,
} from '../../common/table/hooks/use-table-filters/use-table-filters.atoms';
import { getGenericAtomFamily } from '../../../helpers/get-generic-atom-family';
import { SortBy } from '../../common/table/hooks/use-table-filters/use-table-filters.types';
import { AtomPageName } from '../../../helpers/get-generic-atom-family/get-generic-atom-family.types';
import { LocationType } from '../../../pages/machines/machines.types';

export const machinesFiltersDefaults: {
  filters: {
    attentionOnly: boolean;
    difficulty: MachineIssueDifficultyWithMultiple[];
    locations: LocationType[];
    machineIssues: MachineRepairIssueType[];
    statuses: MachineStatus[];
    types: MachineType[];
  };
  sortBy: SortBy<MachinesSortingField>;
} = {
  filters: {
    attentionOnly: false,
    difficulty: [],
    locations: [],
    machineIssues: [],
    statuses: [
      machineStatuses.inProgress,
      machineStatuses.manual,
      machineStatuses.offline,
      machineStatuses.ready,
    ],
    types: [],
  },
  sortBy: {
    sortingField: 'name',
    sortingType: 'ASC',
  },
};

const atomKeyPrefix = 'MACHINES_FILTERS';

export const pageName: AtomPageName = 'machines';

export const machinesPageAtoms = {
  defaults: machinesFiltersDefaults,
  filters: {
    attentionOnly: getGenericAtomFamily({
      defaultValue: machinesFiltersDefaults.filters.attentionOnly,
      key: `${atomKeyPrefix}-attention-only`,
    })(pageName),
    difficulty: getGenericAtomFamily({
      defaultValue: machinesFiltersDefaults.filters.difficulty,
      key: `${atomKeyPrefix}-difficulty`,
    })(pageName),
    locations: getGenericAtomFamily({
      defaultValue: machinesFiltersDefaults.filters.locations,
      key: `${atomKeyPrefix}-locations`,
    })(pageName),
    machineIssues: getGenericAtomFamily({
      defaultValue: machinesFiltersDefaults.filters.machineIssues,
      key: `${atomKeyPrefix}-machine-issues`,
    })(pageName),
    statuses: getGenericAtomFamily({
      defaultValue: machinesFiltersDefaults.filters.statuses,
      key: `${atomKeyPrefix}-statuses`,
    })(pageName),
    types: getGenericAtomFamily({
      defaultValue: machinesFiltersDefaults.filters.types,
      key: `${atomKeyPrefix}-types`,
    })(pageName),
  },
  search: getSearchFilterAtomFamily(pageName),
  sortBy: getSortByFilterAtomFamily<SortBy<MachinesSortingField>>({
    defaultValue: machinesFiltersDefaults.sortBy,
  })(pageName),
};
