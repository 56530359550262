import { useGetInvalidateQueryCache } from '../../../../hooks/use-fetch/use-query-fetch';
import { useGenericFetchQuery } from '../../../../hooks/use-generic-fetch-query';
import { getGenericQueryObj } from '../../../../hooks/use-generic-fetch-query/helpers/get-generic-query-obj';
import { GenericFetchProps } from '../../../../hooks/use-generic-fetch-query/use-generic-fetch-query.types';
import { GetUserArgs, GetUserResponse } from './get-user.types';
import { getUser } from './get-user';

const fetchUserQueriesObj = getGenericQueryObj({
  groupName: 'fetch-user',
  query: getUser,
});

export const useFetchUser = <TransformedData = GetUserResponse>(
  props?: GenericFetchProps<GetUserArgs, GetUserResponse, TransformedData>,
) => {
  return useGenericFetchQuery({
    fallbackErrorMessage: 'Unable to fetch user.',
    queryObj: fetchUserQueriesObj,
    ...props,
  });
};

export const useInvalidateFetchUserCache = () => {
  const invalidateFetchUserCache =
    useGetInvalidateQueryCache(fetchUserQueriesObj);

  return { invalidateFetchUserCache };
};
