import { Conditional, Stack, Typography } from 'gantri-components';
import { ChecklistItem } from '../job-checklist/job-checklist.types';
import { getFailingReasonsFromChecklist } from '../../helpers/get-failing-reasons-from-checklist';
import { JobFailReasonsListProps } from './job-fail-reasons-list.types';
import { getSurfacesStatus } from '../job-checklist/components/advanced-checklist/helpers/get-surfaces-status';
import { List } from '../../../../../../components/common/list';

export const JobFailReasonsList = (props: JobFailReasonsListProps) => {
  const { checklist, title = 'Failure reasons' } = props;

  const failReasons = getFailingReasonsFromChecklist(checklist);

  const checklistItems: ChecklistItem[] = failReasons.map((reason) => {
    return checklist[reason];
  });

  return (
    <Stack gap="x">
      <Typography text={title} variant="h5" />
      <List>
        {checklistItems.map((checklistItem) => {
          const { isPrimarySurfaceFailing, isSecondarySurfaceFailing } =
            getSurfacesStatus(checklistItem);
          const {
            details,
            label,
            ratingPrimarySurface,
            ratingSecondarySurface,
          } = checklistItem;

          return (
            <li key={label}>
              <Typography display="inline" text={label} />
              <Conditional condition={isPrimarySurfaceFailing}>
                &nbsp;
                <Typography
                  display="inline"
                  text={`(Surface 1, Severity ${ratingPrimarySurface})`}
                />
              </Conditional>
              <Conditional condition={isSecondarySurfaceFailing}>
                &nbsp;
                <Typography
                  display="inline"
                  text={`(Surface 2, Severity ${ratingSecondarySurface})`}
                />
              </Conditional>
              <Typography
                color="t2"
                htmlText={details.replace('\n', '<br />')}
              />
            </li>
          );
        })}
      </List>
    </Stack>
  );
};
