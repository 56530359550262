import { useGenericMutateQuery } from '../../../../hooks/use-generic-mutate-query';
import { GenericMutateQueryProps } from '../../../../hooks/use-generic-mutate-query/use-generic-mutate-query.types';
import { UpdateUserArgs, UpdateUserResponse } from './update-user.types';
import { updateUser } from './update-user';

export const useUpdateUser = <TransformedData = UpdateUserResponse>(
  props?: GenericMutateQueryProps<
    UpdateUserArgs,
    UpdateUserResponse,
    TransformedData
  >,
) => {
  const { onMutate: onUpdateUser, ...rest } = useGenericMutateQuery({
    fallbackErrorMessage: 'Unable to update user.',
    mutationFn: updateUser,
    ...props,
  });

  return { ...rest, onUpdateUser };
};
