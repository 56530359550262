import React, { FC, useMemo } from 'react';
import {
  Button,
  Dropdown,
  FormikInput,
  Modal,
  Stack,
  Typography,
} from 'gantri-components';
import { Form, Formik } from 'formik';
import { useToggle } from 'react-use';
import { UserTypeModalProps } from './user-type-modal.types';
import { UserTypeModalPresets } from './user-type-modal.presets';
import { useNotification } from '../../../hooks/useNotification';
import { SetUserTypeArgs } from '../../../api/users/routes/set-user-type/set-user-type.types';
import { getTypesAvailable } from './user-type-modal.adapter';
import { useInvalidateFetchPaginatedUsersCache } from '../../../api/users/routes';
import { usersApi } from '../../../api';

export const UserTypeModal: FC<UserTypeModalProps> = (props) => {
  const { currentUser, currentUserType, onClose, updateUser } = props;
  const { notify, notifyAxiosError } = useNotification();

  const { invalidateFetchPaginatedUsersCache } =
    useInvalidateFetchPaginatedUsersCache();

  const availableUserTypes = useMemo(() => {
    return getTypesAvailable(currentUserType);
  }, [currentUserType]);

  const [processing, setProcessing] = useToggle(false);

  const onConfirm = async ({ type }: { type: SetUserTypeArgs['type'] }) => {
    setProcessing(true);

    try {
      const { data } = await usersApi.setUserType({
        type,
        userId: currentUser.userId,
      });

      if (data) {
        notify(data.notice);
        updateUser({
          ...currentUser,
          accountType: data.user.account.type,
        });
      }

      onClose();
      await invalidateFetchPaginatedUsersCache();
    } catch (error: unknown) {
      notifyAxiosError({
        error,
        fallbackMessage: 'Unable to update the user type',
      });
    } finally {
      setProcessing(false);
    }
  };

  return (
    <Formik
      initialValues={{ type: availableUserTypes[0].value }}
      onSubmit={onConfirm}
    >
      {({ isValid }: any) => {
        return (
          <Form>
            <Modal
              footer={
                <>
                  <Button
                    size="large"
                    text="Cancel"
                    variant="secondary"
                    onClick={onClose}
                  />
                  <Button
                    disabled={!isValid}
                    processing={processing}
                    size="large"
                    text="Confirm"
                    type="submit"
                  />
                </>
              }
              header="Change user type"
              width={{ lg: '40rem', md: '100%' }}
              onClose={onClose}
            >
              <Stack alignContent="center">
                <Stack gap="0">
                  <Typography text="Current" textStyle="bold" />
                  <Typography text={currentUserType} />
                </Stack>

                <FormikInput
                  ariaLabel="change-to"
                  Field={
                    <Dropdown
                      items={availableUserTypes}
                      placeholder="Change to"
                    />
                  }
                  labelText="Change to"
                  name="type"
                />
              </Stack>
            </Modal>
          </Form>
        );
      }}
    </Formik>
  );
};

UserTypeModal.defaultProps = UserTypeModalPresets;
