import { Button, Conditional } from 'gantri-components';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
  useCompleteJob,
  useFailJob,
} from '../../../../../../../../../api/jobs/routes';
import { JobPassOrFailReasonDetails } from '../../../../../../../../../api/jobs/routes/fail-job/fail-job.types';
import { FailedReason } from '../../../../../../../../../api/jobs/routes/get-fail-reasons-list/get-fail-reasons-list.types';
import { jobTypeOptions } from '../../../../../../../../../constants/options';
import {
  checklistDefaults,
  ulChecklistMaxValues,
  ulChecklistUnits,
} from '../../../../../common/job-checklist/job-checklist.constants';
import { checkAgainstChecklistItems } from '../../../../../helpers/check-against-checklist-items';
import { getFailJobReasonArg } from '../../../../../helpers/get-fail-job-reason-arg';
import { fullScreenModalFooterButtonSize } from '../../../../../modals.constants';
import { completeQaQcModalAtoms } from '../../../complete-final-qa-modal.atoms';
import { qaQcJobModalSteps } from '../../../complete-final-qa-modal.constants';
import { CompleteUlChecklistFooterPropsDef } from './complete-ul-checklist-footer.types';

export const CompleteUlChecklistFooter = (
  props: CompleteUlChecklistFooterPropsDef,
) => {
  const { handleCloseModal, job } = props;

  const setDuplicatedJobs = useSetRecoilState(
    completeQaQcModalAtoms.duplicatedJobs,
  );
  const checklist = useRecoilValue(completeQaQcModalAtoms.checklist);
  const [ulChecklist, setUlChecklist] = useRecoilState(
    completeQaQcModalAtoms.ulChecklist,
  );
  const isUlChecklistReviewed = useRecoilValue(
    completeQaQcModalAtoms.isUlChecklistReviewed,
  );
  const setStep = useSetRecoilState(completeQaQcModalAtoms.step);
  const setUpdateOnClose = useSetRecoilState(
    completeQaQcModalAtoms.updateOnClose,
  );

  const { isLoading: isCompletingJob, onCompleteJob } = useCompleteJob({
    onSuccess: async () => {
      await handleCloseModal({ updateOnClose: true });
    },
  });

  const onComplete = async () => {
    const reason = getUlChecklistReasons('Pass');

    await onCompleteJob({
      jobId: job.id,
      reason,
    });
  };

  const { isLoading: isFailingJob, onFailJob } = useFailJob({
    onSuccess: async ({ duplicatedJobs }, { reason }) => {
      if (!!duplicatedJobs?.length) {
        setDuplicatedJobs(duplicatedJobs);
        setUpdateOnClose(true);
      }

      setUlChecklist({
        ul_measurement: {
          ...checklistDefaults.ul_measurement,
          details: reason.ul_measurement.details,
          status: reason.ul_measurement.status,
        },
      });
      setStep(qaQcJobModalSteps.JOB_FAILED);
    },
  });

  const getUlChecklistReasons = (status: 'Pass' | 'Fail') => {
    const reason = getFailJobReasonArg(ulChecklist);

    const acwValue =
      reason.acw_hi_pot.status === 'Fail'
        ? ulChecklistMaxValues.acw_hi_pot
        : reason.acw_hi_pot.details;
    const irValue =
      reason.ir.status === 'Fail' ? reason.ir.details : ulChecklistMaxValues.ir;

    const details = [
      `ACW: ${acwValue} ${ulChecklistUnits.acw_hi_pot}`,
      `IR: ${irValue} ${ulChecklistUnits.ir}`,
    ].join('\n');

    const ulChecklistReason: JobPassOrFailReasonDetails<FailedReason> = {
      ul_measurement: {
        advancedInfo: {
          material: undefined,
          surface: undefined,
          value: undefined,
        },
        details,
        status,
      },
    };

    return ulChecklistReason;
  };

  const onFail = async () => {
    const checklistReasons = getFailJobReasonArg(checklist);
    const ulChecklistReasons = getUlChecklistReasons('Fail');

    await onFailJob({
      duplicateType: 'Reassemble',
      jobId: job.id,
      reason: { ...checklistReasons, ...ulChecklistReasons },
    });
  };

  const isChecklistFailing = checkAgainstChecklistItems(
    ulChecklist,
    ({ status }) => {
      return status === 'Fail';
    },
  );

  return (
    <>
      <Button
        size={fullScreenModalFooterButtonSize}
        text="Back"
        variant="secondary"
        onClick={() => {
          setStep(qaQcJobModalSteps.COMPLETE_CHECKLIST);
        }}
      />
      <Conditional
        condition={isChecklistFailing}
        Fallback={
          <Button
            disabled={!isUlChecklistReviewed}
            processing={isCompletingJob}
            size={fullScreenModalFooterButtonSize}
            text={`Confirm ${jobTypeOptions.qc} Completion`}
            variant="primary"
            onClick={onComplete}
          />
        }
      >
        <Button
          disabled={!isUlChecklistReviewed}
          processing={isFailingJob}
          size={fullScreenModalFooterButtonSize}
          text="Fail QC"
          variant="primaryAlert"
          onClick={onFail}
        />
      </Conditional>
    </>
  );
};
