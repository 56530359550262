import { MachineStatus, machineStatuses } from '../../../../constants/machines';
import { convertStringArrayToValueLabelArray } from '../../../../helpers/formatter';

export const machineStatusOptions = convertStringArrayToValueLabelArray([
  machineStatuses.ready,
  machineStatuses.manual,
  machineStatuses.offline,
  machineStatuses.archived,
]);

export const machineStatusDetailsMap = {
  [machineStatuses.archived]: 'Has been removed from prod.',
  [machineStatuses.manual]: 'Must be assigned a print',
  [machineStatuses.offline]: 'Needs Repair or Maintenance',
  [machineStatuses.ready]: 'Automatically accepts prints',
} satisfies Partial<Record<MachineStatus, string>>;
