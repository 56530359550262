import { MachineRecord } from '../../machines.types';
import { MachineStatus, machineStatuses } from '../../../../constants/machines';

export const getSetOnlineDisabled = (
  machine: Pick<MachineRecord, 'openIssuesCount' | 'status'>,
): boolean => {
  const onlineStatuses: MachineStatus[] = [
    machineStatuses.archived,
    machineStatuses.inProgress,
    machineStatuses.ready,
  ];

  const isAlreadyOnline = onlineStatuses.some((invalidStatus) => {
    return invalidStatus === machine?.status;
  });
  const isOfflineWithOpenIssues =
    machine?.status === machineStatuses.offline && !!machine?.openIssuesCount;

  return isAlreadyOnline || isOfflineWithOpenIssues;
};
