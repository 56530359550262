import { useGenericMutateQuery } from '../../../../hooks/use-generic-mutate-query';
import { GenericMutateQueryProps } from '../../../../hooks/use-generic-mutate-query/use-generic-mutate-query.types';
import { FailJobArgs, FailJobResponse } from './fail-job.types';
import { failJob } from './fail-job';

export const useFailJob = <TransformedData = FailJobResponse>(
  props?: GenericMutateQueryProps<
    FailJobArgs,
    FailJobResponse,
    TransformedData
  >,
) => {
  const { onMutate: onFailJob, ...rest } = useGenericMutateQuery({
    fallbackErrorMessage: 'Unable to fail job.',
    mutationFn: failJob,
    ...props,
  });

  return { ...rest, onFailJob };
};
