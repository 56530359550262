import axios from 'axios';
import { coreApiUrl } from '../../../../helpers/auth';
import { GetUserArgs, GetUserResponse } from './get-user.types';

export const getUser = ({ userId }: GetUserArgs) => {
  return axios
    .post<GetUserResponse>(`${coreApiUrl}/get-user/${userId}`, null)
    .then(({ data }) => {
      return data;
    });
};
