import { Dropdown, FormikInput, Stack, TextField } from 'gantri-components';
import { useMemo } from 'react';
import { StatusItem } from '../../../../../machine/sections/general-section/status-item';
import { machineTypeOptions } from '../../../../machine.constants';
import { machineStatusOptions } from '../../../../../machine/sections/general-section/general-section.constant';
import { machineStatuses } from '../../../../../../constants/machines';
import { AddOrEditMachineModalContentPropsDef } from './add-or-edit-machine-modal-content.types';

export const AddOrEditMachineModalContent = (
  props: AddOrEditMachineModalContentPropsDef,
) => {
  const { isEditing } = props;

  const statusItems = useMemo(() => {
    return isEditing
      ? machineStatusOptions
      : machineStatusOptions.filter(({ value }) => {
          return value !== machineStatuses.archived;
        });
  }, [isEditing]);

  return (
    <Stack gap="2x">
      <FormikInput
        Field={
          <TextField labelText="Name" placeholder="Machine Name" required />
        }
        name="name"
      />

      <FormikInput
        Field={
          <Dropdown
            items={machineTypeOptions}
            labelText="Type"
            placeholder="Machine Type"
            required
          />
        }
        name="type"
      />

      <FormikInput
        Field={
          <TextField labelText="Location" placeholder="Location" required />
        }
        name="location"
      />

      <FormikInput
        Field={
          <Dropdown
            items={statusItems}
            labelText="Status"
            maxHeight={400}
            placeholder="Please select"
            renderItem={(option) => {
              return <StatusItem showDescription status={option?.label} />;
            }}
            renderPlaceholder={(option) => {
              return (
                <StatusItem
                  status={option?.label}
                  style={{ paddingLeft: '.8rem' }}
                />
              );
            }}
            required
          />
        }
        name="status"
      />
    </Stack>
  );
};
