import { convertStringArrayToValueLabelArray } from '../../helpers/formatter';

export const tradeStatuses = {
  no: 'No',
  pending: 'Pending',
  yes: 'Yes',
} as const;

export const tradeStatusOptions = convertStringArrayToValueLabelArray(
  Object.values(tradeStatuses),
);
