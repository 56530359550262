import { Badge } from 'gantri-components';
import { useTheme } from 'styled-components';
import { FC } from 'react';
import { MachineStatusDataCellType } from './machine-status-data-cell.types';
import { machineStatuses } from '../../../../constants/machines';

export const MachineStatusesDataCell: FC<MachineStatusDataCellType> = ({
  status,
}: MachineStatusDataCellType) => {
  const theme = useTheme();

  switch (status) {
    case machineStatuses.ready: {
      return (
        <Badge
          borderColor={theme.colors.surfaces.green.t2}
          color={theme.colors.surfaces.green.t1}
          icon="actions:thumbs_up"
          text={status}
          textColor={theme.colors.typography.t1}
        />
      );
    }

    case machineStatuses.manual: {
      return (
        <Badge
          borderColor={theme.colors.surfaces.blue.t2}
          color={theme.colors.surfaces.blue.t1}
          icon="people:person"
          text={status}
          textColor={theme.colors.typography.t1}
        />
      );
    }

    case machineStatuses.inProgress: {
      return (
        <Badge
          borderColor={theme.colors.surfaces.warning.t2}
          color={theme.colors.surfaces.warning.t1}
          icon="time:clock"
          minWidth="10.5rem"
          text={status}
          textColor={theme.colors.typography.t1}
        />
      );
    }

    case machineStatuses.offline: {
      return (
        <Badge
          borderColor={theme.colors.surfaces.alert.t2}
          color={theme.colors.surfaces.alert.t1}
          icon="ui-control:x"
          text={status}
          textColor={theme.colors.typography.t1}
        />
      );
    }

    case machineStatuses.archived: {
      return (
        <Badge
          borderColor={theme.colors.surfaces.monochrome.t3}
          color={theme.colors.surfaces.monochrome.overlayLevel2}
          icon="ui-control:minus_circle"
          text={status}
          textColor={theme.colors.typography.t1}
        />
      );
    }

    default:
      return (
        <Badge
          borderColor={theme.colors.surfaces.alert.t2}
          color={theme.colors.surfaces.alert.t1}
          text={status}
          textColor={theme.colors.typography.t1}
        />
      );
  }
};
