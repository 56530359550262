import axios from 'axios';
import { coreApiUrl } from '../../../../helpers/auth';
import {
  GetPaginatedUsersArgs,
  GetPaginatedUsersResponse,
} from './get-paginated-users.types';

export const fetchPaginatedUsers = (body: GetPaginatedUsersArgs) => {
  return axios
    .post<GetPaginatedUsersResponse>(`${coreApiUrl}/users`, body)
    .then(({ data }) => {
      return data;
    });
};
