import {
  MachineStatus,
  machineStatuses,
} from '../../../../../../constants/machines';

export const getIsMachineActive = (machineStatus: MachineStatus) => {
  const isInactive = [machineStatuses.offline, machineStatuses.archived].some(
    (status) => {
      return status === machineStatus;
    },
  );

  return !isInactive;
};
