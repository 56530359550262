import { Flex } from 'gantri-components';
import { RFIDCell } from '../../../../../../components/common/custom-data-cells/rfid-data-cell';
import { JobPartCellProps } from './job-part-cell.types';
import { StyledAnchor } from '../../../../../../components/common/styled-anchor';
import routePaths from '../../../../../../config/route-paths';

export const JobPartCell = ({ getValue, row }: JobPartCellProps) => {
  const { stockId } = row.original;

  const part = getValue();

  if (part) {
    const { id: partId, name: partName } = part;

    return (
      <Flex alignItems="center" gap="x">
        <RFIDCell partId={partId} stockId={stockId} />
        <StyledAnchor
          href={`${routePaths.parts}/${part?.id}`}
          text={partName}
        />
      </Flex>
    );
  }

  return null;
};
