import { Button } from 'gantri-components';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { completeAssemblyModalAtoms } from '../../../complete-assembly-modal.atoms';
import { fullScreenModalFooterButtonSize } from '../../../../../modals.constants';
import { useOnFail } from './hooks/use-on-fail';
import { CompleteChecklistButtonWrapper } from '../../../../../common/job-checklist/components/complete-checklist-button-wrapper';

export const PartChecklistFooter = () => {
  const setStep = useSetRecoilState(completeAssemblyModalAtoms.step);
  const isPartChecklistReviewed = useRecoilValue(
    completeAssemblyModalAtoms.isPartChecklistReviewed,
  );

  const partChecklist = useRecoilValue(
    completeAssemblyModalAtoms.partChecklist,
  );

  const { failingPartIds, isLoading, onFail } = useOnFail();

  const isPartFailed = !!failingPartIds.length;

  return (
    <>
      <Button
        size={fullScreenModalFooterButtonSize}
        text="Back"
        variant="secondary"
        onClick={() => {
          setStep('JOB_CHECKLIST');
        }}
      />

      <CompleteChecklistButtonWrapper checklist={partChecklist}>
        <Button
          disabled={!isPartChecklistReviewed || !isPartFailed}
          processing={isLoading}
          size={fullScreenModalFooterButtonSize}
          text="Confirm Assembly Fail"
          variant="primaryAlert"
          onClick={onFail}
        />
      </CompleteChecklistButtonWrapper>
    </>
  );
};
