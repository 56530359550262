import { MachineRecord } from '../../machines.types';
import { MachineStatus, machineStatuses } from '../../../../constants/machines';
import { getIsCrealityMachine } from '../get-is-creality-machine';

export const getResetBuildPlateDisabled = ({
  status,
  type,
}: Pick<MachineRecord, 'status' | 'type'>): boolean => {
  const isCrealityMachine = getIsCrealityMachine({ type });

  const disabledStatuses: MachineStatus[] = [
    machineStatuses.archived,
    machineStatuses.inProgress,
    machineStatuses.offline,
  ];

  const isDisabledStatus = disabledStatuses.some((disabledStatus) => {
    return disabledStatus === status;
  });

  return !isCrealityMachine || isDisabledStatus;
};
