import { useRecoilValue } from 'recoil';
import { completeQaQcModalAtoms } from '../../complete-final-qa-modal.atoms';
import { QaQcJobContentProps } from './qa-qc-job-content.types';
import { ConfirmStartContent } from '../step-confirm-start/confirm-start-content';
import { CompleteChecklistContent } from '../step-complete-checklist/complete-checklist-content';
import { JobFailedContent } from '../step-job-failed/job-failed-content';
import { qaQcJobModalSteps } from '../../complete-final-qa-modal.constants';
import { CompleteUlChecklistContent } from '../step-complete-ul-checklist/complete-ul-checklist-content';

export const QaQcJobContent = (props: QaQcJobContentProps) => {
  const { job } = props;

  const step = useRecoilValue(completeQaQcModalAtoms.step);

  switch (step) {
    case qaQcJobModalSteps.CONFIRM_START:
      return <ConfirmStartContent job={job} />;

    case qaQcJobModalSteps.COMPLETE_CHECKLIST:
      return <CompleteChecklistContent />;

    case qaQcJobModalSteps.COMPLETE_UL_CHECKLIST:
      return <CompleteUlChecklistContent job={job} />;

    case qaQcJobModalSteps.JOB_FAILED:
      return <JobFailedContent />;

    default:
      return null;
  }
};
