import { useGenericMutateQuery } from '../../../../hooks/use-generic-mutate-query';
import { GenericMutateQueryProps } from '../../../../hooks/use-generic-mutate-query/use-generic-mutate-query.types';
import { updateTradeStatus } from './update-trade-status';
import {
  UpdateTradeStatusArgsDef,
  UpdateTradeStatusResponseDef,
} from './update-trade-status.types';

export const useUpdateTradeStatus = <
  TransformedData = UpdateTradeStatusResponseDef,
>(
  props?: GenericMutateQueryProps<
    UpdateTradeStatusArgsDef,
    UpdateTradeStatusResponseDef,
    TransformedData
  >,
) => {
  const { onMutate: onUpdateTradeStatus, ...rest } = useGenericMutateQuery({
    fallbackErrorMessage: 'Unable to update trade status.',
    mutationFn: updateTradeStatus,
    ...props,
  });

  return { ...rest, onUpdateTradeStatus };
};
