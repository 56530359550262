import { Stack, Typography } from 'gantri-components';
import { useRecoilValue } from 'recoil';
import {
  jobSteps,
  jobTypeOptions,
} from '../../../../../../../../../constants/options';
import { JobFailReasonsList } from '../../../../../common/job-fail-reasons-list';
import { ModalContentHeading } from '../../../../../common/modal-content-heading';
import { DuplicatedJobsSection } from '../../../../../common/duplicated-jobs-section';
import { completeQaQcModalAtoms } from '../../../complete-final-qa-modal.atoms';

export const JobFailedContent = () => {
  const isUlChecklistReviewed = useRecoilValue(
    completeQaQcModalAtoms.isUlChecklistReviewed,
  );
  const ulChecklist = useRecoilValue(completeQaQcModalAtoms.ulChecklist);
  const checklist = useRecoilValue(completeQaQcModalAtoms.checklist);
  const duplicatedJobs = useRecoilValue(completeQaQcModalAtoms.duplicatedJobs);

  return (
    <Stack gap="4x">
      <ModalContentHeading
        color="alert"
        titleText={`${jobSteps.qa} ${jobTypeOptions.qc} job failed`}
      />
      <Stack gap="x">
        <Typography text="Return to Assembly" variant="h5" />
        <Typography text="Stock has failed. Please return stock to Assembly and move on to the next job." />
      </Stack>
      <JobFailReasonsList
        checklist={isUlChecklistReviewed ? ulChecklist : checklist}
      />
      <DuplicatedJobsSection duplicatedJobs={duplicatedJobs} showTitle />
    </Stack>
  );
};
