import { Cell, Dropdown, Toggle } from 'gantri-components';
import { useRecoilState } from 'recoil';
import { tradeStatusOptions } from '../../../api/users/users.constants';
import { AtomsCheckboxList } from '../../common/atoms-checkbox-list';
import { FilterGrid, FilterRow } from '../../common/filter-grid';
import { FilterResetLabel } from '../../common/filter-reset-label';
import { usersFiltersDefaults, usersPageAtoms } from './users-filter.atoms';
import { usersTypesOptions } from './users-filter.constants';

export const UsersFilter = () => {
  const [active, setActive] = useRecoilState(usersPageAtoms.filters.active);
  const [tradeStatus, setTradeStatus] = useRecoilState(
    usersPageAtoms.filters.tradeStatus,
  );

  const tradeStatusItems = [
    { label: 'Select', value: usersFiltersDefaults.filters.tradeStatus },
    ...tradeStatusOptions,
  ];

  return (
    <FilterGrid>
      <FilterRow>
        <FilterResetLabel
          atom={usersPageAtoms.filters.types}
          default={usersPageAtoms.defaults.filters.types}
          marginBottom="1rem"
          text="Type"
        />
        <AtomsCheckboxList
          atom={usersPageAtoms.filters.types}
          gridProps={{ columns: 2 }}
          items={[...usersTypesOptions]}
        />
      </FilterRow>

      <FilterRow>
        <FilterResetLabel
          atom={usersPageAtoms.filters.active}
          default={usersPageAtoms.defaults.filters.active}
          marginBottom="1rem"
          text="Active Users Only"
        />
        <Cell justifyContent="start">
          <Toggle name="show-all" value={active} onSelected={setActive} />
        </Cell>
      </FilterRow>

      <FilterRow>
        <FilterResetLabel
          atom={usersPageAtoms.filters.tradeStatus}
          default={usersPageAtoms.defaults.filters.tradeStatus}
          marginBottom="1rem"
          text="Trade status"
        />
        <Cell>
          <Dropdown
            items={tradeStatusItems}
            placeholder="Select"
            value={tradeStatus}
            onSelect={(item) => {
              setTradeStatus(item?.value);
            }}
          />
        </Cell>
      </FilterRow>
    </FilterGrid>
  );
};
