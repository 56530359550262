import Hotjar from '@hotjar/browser';
import { environment } from '../environment';

export const setHotjarUserAttribute = (
  userId: string | number,
  attr: Record<string, unknown>,
) => {
  if (!environment.IS_PRODUCTION) {
    return;
  }

  // @ts-ignore
  Hotjar.identify(userId, attr);
};

export const initializeHotjar = () => {
  if (!environment.IS_PRODUCTION) {
    return;
  }

  // @ts-ignore
  Hotjar.init(3848366, 6);
};
