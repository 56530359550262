import { ProductColorCode } from 'gantri-components';
import { UserStatus } from '../../../api/jobs/routes/get-paginated-jobs/get-paginated-jobs.types';
import { MachineType } from '../../../api/machines/machines.types';
import {
  JobStatus,
  jobStatuses,
  JobStep,
  JobType,
} from '../../../constants/options';
import { Material } from '../../../constants/options/options.types';
import { DateRangeFilter } from '../../common/table/components/table-filters/table-filters.types';
import { getSearchFilterAtomFamily } from '../../common/table/hooks/use-table-filters/use-table-filters.atoms';
import { getGenericAtomFamily } from '../../../helpers/get-generic-atom-family';
import { AtomPageName } from '../../../helpers/get-generic-atom-family/get-generic-atom-family.types';
import { JobsSortingField } from './job-filter.types';

export const jobsFiltersDefaults: {
  filters: {
    assignedTo: number[];
    attentionOnly: boolean;
    colors: ProductColorCode[];
    completedDateRange: DateRangeFilter;
    createdDateRange: DateRangeFilter;
    late: boolean;
    locations: string[];
    machineIssues: string[];
    machineTypes: MachineType[];
    materials: Material[];
    orderId: number;
    productId: number;
    sortBy: JobsSortingField;
    statuses: JobStatus[];
    steps: JobStep[];
    types: JobType[];
    userStatuses: UserStatus[];
    users: number[];
  };
} = {
  filters: {
    assignedTo: [],
    attentionOnly: false,
    colors: [],
    completedDateRange: {
      from: null,
      to: null,
    },
    createdDateRange: {
      from: null,
      to: null,
    },
    late: false,
    locations: [],
    machineIssues: [],
    machineTypes: [],
    materials: [],
    orderId: null,
    productId: null,
    sortBy: 'Priority',
    statuses: [jobStatuses.ready, jobStatuses.inProgress],
    steps: [],
    types: [],
    userStatuses: [],
    users: [],
  },
};

const atomKeyPrefix = 'JOBS_FILTERS';

export const pageName: AtomPageName = 'jobs';

export const jobsPageAtoms = {
  defaults: jobsFiltersDefaults,
  filters: {
    assignedTo: getGenericAtomFamily({
      defaultValue: jobsFiltersDefaults.filters.assignedTo,
      key: `${atomKeyPrefix}-assigned-to`,
    })(pageName),
    attentionOnly: getGenericAtomFamily({
      defaultValue: jobsFiltersDefaults.filters.attentionOnly,
      key: `${atomKeyPrefix}-attention-only`,
    })(pageName),
    colors: getGenericAtomFamily({
      defaultValue: jobsFiltersDefaults.filters.colors,
      key: `${atomKeyPrefix}-colors`,
    })(pageName),
    completedDateRange: getGenericAtomFamily({
      defaultValue: jobsFiltersDefaults.filters.completedDateRange,
      key: `${atomKeyPrefix}-completed-date-range`,
    })(pageName),
    createdDateRange: getGenericAtomFamily({
      defaultValue: jobsFiltersDefaults.filters.createdDateRange,
      key: `${atomKeyPrefix}-created-date-range`,
    })(pageName),
    late: getGenericAtomFamily({
      defaultValue: jobsFiltersDefaults.filters.late,
      key: `${atomKeyPrefix}-late`,
    })(pageName),
    locations: getGenericAtomFamily({
      defaultValue: jobsFiltersDefaults.filters.locations,
      key: `${atomKeyPrefix}-locations`,
    })(pageName),
    machineIssues: getGenericAtomFamily({
      defaultValue: jobsFiltersDefaults.filters.machineIssues,
      key: `${atomKeyPrefix}-machine-issues`,
    })(pageName),
    machineTypes: getGenericAtomFamily({
      defaultValue: jobsFiltersDefaults.filters.machineTypes,
      key: `${atomKeyPrefix}-machine-types`,
    })(pageName),
    materials: getGenericAtomFamily({
      defaultValue: jobsFiltersDefaults.filters.materials,
      key: `${atomKeyPrefix}-materials`,
    })(pageName),
    orderId: getGenericAtomFamily({
      defaultValue: jobsFiltersDefaults.filters.orderId,
      key: `${atomKeyPrefix}-order-id`,
    })(pageName),
    productId: getGenericAtomFamily({
      defaultValue: jobsFiltersDefaults.filters.productId,
      key: `${atomKeyPrefix}-product-id`,
    })(pageName),
    statuses: getGenericAtomFamily({
      defaultValue: jobsFiltersDefaults.filters.statuses,
      key: `${atomKeyPrefix}-statuses`,
    })(pageName),
    steps: getGenericAtomFamily({
      defaultValue: jobsFiltersDefaults.filters.steps,
      key: `${atomKeyPrefix}-steps`,
    })(pageName),
    types: getGenericAtomFamily({
      defaultValue: jobsFiltersDefaults.filters.types,
      key: `${atomKeyPrefix}-types`,
    })(pageName),
    userStatuses: getGenericAtomFamily({
      defaultValue: jobsFiltersDefaults.filters.userStatuses,
      key: `${atomKeyPrefix}-user-statuses`,
    })(pageName),
    users: getGenericAtomFamily({
      defaultValue: jobsFiltersDefaults.filters.users,
      key: `${atomKeyPrefix}-user-ids`,
    })(pageName),
  },
  search: getSearchFilterAtomFamily(pageName),
  sortBy: getGenericAtomFamily({
    defaultValue: jobsFiltersDefaults.filters.sortBy,
    key: `${atomKeyPrefix}-sort-by`,
  })(pageName),
};
