import { Button, Dropdown, Modal, Stack, Typography } from 'gantri-components';
import { useState } from 'react';
import {
  useInvalidateFetchUserCache,
  useUpdateTradeStatus,
} from '../../../../api/users/routes';
import { tradeStatusOptions } from '../../../../api/users/users.constants';
import { UserTradeStatus } from '../../../../api/users/users.types';
import { Label } from '../../../../components/label';
import { TradeStatusModalPropsDef } from './trade-status-modal.types';
import { setHotjarUserAttribute } from '../../../../helpers/hotjar';

export const TradeStatusModal = (props: TradeStatusModalPropsDef) => {
  const { onClose, tradeStatus, userId } = props;

  const [newTradeStatus, setNewTradeStatus] =
    useState<UserTradeStatus>(tradeStatus);

  const availableTradeStatuses = tradeStatusOptions.filter(({ value }) => {
    return value !== tradeStatus;
  });

  const { invalidateFetchUserCache } = useInvalidateFetchUserCache();

  const { isLoading, onUpdateTradeStatus } = useUpdateTradeStatus({
    onSuccess: async () => {
      await invalidateFetchUserCache();
      onClose();
    },
  });

  const onSubmit = async () => {
    await onUpdateTradeStatus({
      tradeStatus: newTradeStatus,
      userId,
    });

    if (newTradeStatus === 'Yes') {
      setHotjarUserAttribute(userId, { Trade: true });
    }
  };

  return (
    <Modal
      footer={
        <>
          <Button
            size="large"
            text="Cancel"
            variant="secondary"
            onClick={onClose}
          />
          <Button
            processing={isLoading}
            size="large"
            text="Confirm"
            onClick={async () => {
              await onSubmit();
            }}
          />
        </>
      }
      header="Change trade status"
      maxWidth={{ lg: '40rem', md: '100%' }}
      onClose={onClose}
    >
      <Stack gap="2x">
        <Stack gap=".5x">
          <Label text="Current" />
          <Typography text={tradeStatus} />
        </Stack>

        <Stack gap=".5x">
          <Label text="Change to" />
          <Dropdown
            items={availableTradeStatuses}
            placeholder="Select"
            onSelect={(item) => {
              setNewTradeStatus(item?.value);
            }}
          />
        </Stack>
      </Stack>
    </Modal>
  );
};
