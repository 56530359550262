import { Button, Conditional, Stack } from 'gantri-components';
import { useRecoilState } from 'recoil';
import { useMemo } from 'react';
import { Divider } from '../../../../../../../../../components/divider';
import { Label } from '../../../../../../../../../components/label';
import { AdvancedChecklist } from '../../../../../common/job-checklist/components/advanced-checklist';
import { completeQaQcModalAtoms } from '../../../complete-final-qa-modal.atoms';
import { UpdateChecklist } from '../../../complete-final-qa-modal.types';
import { productCategories } from '../../../../../../../../../api/products/products.constants';
import { ProductCategory } from '../../../../../../../../../api/products/products.types';
import { formatDate } from '../../../../../../../../../helpers/formatter';
import { StyledCSVLink } from './complete-ul-checklist-content.styles';
import { CompleteUlChecklistContentPropsDef } from './complete-ul-checklist-content.types';
import { checkAgainstChecklistItems } from '../../../../../helpers/check-against-checklist-items';

export const CompleteUlChecklistContent = (
  props: CompleteUlChecklistContentPropsDef,
) => {
  const { job } = props;

  const [ulChecklist, setUlChecklist] = useRecoilState(
    completeQaQcModalAtoms.ulChecklist,
  );
  const [isUlChecklistReviewed, setIsUlChecklistReviewed] = useRecoilState(
    completeQaQcModalAtoms.isUlChecklistReviewed,
  );
  const downloadData = useMemo(() => {
    const getCategoryAbbreviation = (category: ProductCategory) => {
      switch (category) {
        case productCategories.pendantLight:
          return 'pen';

        case productCategories.wallSconce:
          return 'scn';

        default:
          return category;
      }
    };

    const categoryAbbreviation = getCategoryAbbreviation(job.product.category);
    const categoryPattern = new RegExp(
      Object.values(productCategories)
        .map((value) => {
          return `(${value})`;
        })
        .join('|'),
      'i',
    );
    const productName = job.product.name.replace(categoryPattern, '').trim();

    return [
      [
        `${categoryAbbreviation}-${productName}-${job.stockId}`,
        formatDate(Date.now(), 'MMddyyyy'),
      ],
    ];
  }, []);

  const updateChecklist: UpdateChecklist = (reason, data) => {
    setUlChecklist((previous) => {
      return {
        ...previous,
        [reason]: data,
      };
    });
  };

  const isChecklistFailing = checkAgainstChecklistItems(
    ulChecklist,
    ({ status }) => {
      return status === 'Fail';
    },
  );

  const DownloadButton = () => {
    return (
      <Button
        disabled={isChecklistFailing}
        size="extraLarge"
        text="Print Label"
        variant="secondary"
        width="100%"
      />
    );
  };

  return (
    <Stack gap="4x">
      <AdvancedChecklist
        buttonToggleWidth="8rem"
        checklist={ulChecklist}
        getSubTitle={() => {
          return '';
        }}
        isChecklistReviewed={isUlChecklistReviewed}
        setIsChecklistReviewed={setIsUlChecklistReviewed}
        statuses={['Pass', 'Fail']}
        title="Evaluate UL Measurements"
        onItemChange={updateChecklist}
      />

      <Divider />

      <Stack gap="x">
        <Label text="Print UL Label" variant="h5" />

        <Conditional
          condition={isChecklistFailing}
          Fallback={
            <StyledCSVLink
              data={downloadData}
              filename={`UL_Label_${job.stockId}`}
            >
              <DownloadButton />
            </StyledCSVLink>
          }
        >
          <DownloadButton />
        </Conditional>
      </Stack>
    </Stack>
  );
};
