import axios from 'axios';
import { getCoreApiUrl } from '../../../../helpers/auth';
import { UpdateTradeStatusArgsDef } from './update-trade-status.types';

export const updateTradeStatus = ({
  userId,
  ...body
}: UpdateTradeStatusArgsDef) => {
  return axios
    .put(`${getCoreApiUrl('users')}/${userId}/trade-status`, body)
    .then(({ data }) => {
      return data;
    });
};
