import axios from 'axios';
import { coreApiUrl } from '../../../../helpers/auth';
import { FailJobArgs, FailJobResponse } from './fail-job.types';

export const failJob = ({ jobId, ...body }: FailJobArgs) => {
  return axios
    .post<FailJobResponse>(`${coreApiUrl}/jobs/${jobId}/fail`, body)
    .then(({ data }) => {
      return data;
    });
};
