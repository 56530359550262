/* eslint-disable sort-keys-fix/sort-keys-fix */
import {
  FinalQAFailedReason,
  UlChecklistFailedReason,
} from '../../../../../../api/jobs/routes/get-fail-reasons-list/get-fail-reasons-list.types';
import { checklistDefaults } from '../../common/job-checklist/job-checklist.constants';
import { Checklist } from '../../common/job-checklist/job-checklist.types';

export const completeFinalQAModalChecklistDefaults: Checklist<FinalQAFailedReason> =
  {
    // keys sorted according to desired display order
    bad_finish: checklistDefaults.bad_finish,
    bad_fit: checklistDefaults.bad_fit,
    damaged_by_assembly: checklistDefaults.damaged_by_assembly,
    wrong_assembly: checklistDefaults.wrong_assembly,
    support_material_or_stringing:
      checklistDefaults.support_material_or_stringing,
    bulb_issue: checklistDefaults.bulb_issue,
    cord_or_switch_issue: checklistDefaults.cord_or_switch_issue,
    other_failure_modes: checklistDefaults.other_failure_modes,
  };

export const completeUlChecklistDefaults: Partial<
  Checklist<UlChecklistFailedReason>
> = {
  // keys sorted according to desired display order
  acw_hi_pot: checklistDefaults.acw_hi_pot,
  ir: checklistDefaults.ir,
};

export const qaQcJobModalSteps = {
  COMPLETE_CHECKLIST: 'COMPLETE_CHECKLIST',
  CONFIRM_START: 'CONFIRM_START',
  JOB_FAILED: 'JOB_FAILED',
  COMPLETE_UL_CHECKLIST: 'COMPLETE_UL_CHECKLIST',
} as const;
