import { useRecoilState } from 'recoil';
import { AdvancedChecklist } from '../../../../../common/job-checklist/components/advanced-checklist';
import { completeQaQcModalAtoms } from '../../../complete-final-qa-modal.atoms';
import { UpdateChecklist } from '../../../complete-final-qa-modal.types';

export const CompleteChecklistContent = () => {
  const [checklist, setChecklist] = useRecoilState(
    completeQaQcModalAtoms.checklist,
  );
  const [isChecklistReviewed, setIsChecklistReviewed] = useRecoilState(
    completeQaQcModalAtoms.isChecklistReviewed,
  );

  const updateChecklist: UpdateChecklist = (reason, data) => {
    setChecklist((previous) => {
      return {
        ...previous,
        [reason]: data,
      };
    });
  };

  return (
    <AdvancedChecklist
      buttonToggleWidth="8rem"
      checklist={checklist}
      isChecklistReviewed={isChecklistReviewed}
      setIsChecklistReviewed={setIsChecklistReviewed}
      showConfirmCheckbox
      statuses={['Pass', 'Fail']}
      onItemChange={updateChecklist}
    />
  );
};
