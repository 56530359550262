import { Button, Conditional } from 'gantri-components';
import { useMemo } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import {
  useCompleteJob,
  useFailJob,
} from '../../../../../../../../../api/jobs/routes';
import { productCategories } from '../../../../../../../../../api/products/products.constants';
import { jobTypeOptions } from '../../../../../../../../../constants/options';
import { checkAgainstChecklistItems } from '../../../../../helpers/check-against-checklist-items';
import { getFailJobReasonArg } from '../../../../../helpers/get-fail-job-reason-arg';
import { fullScreenModalFooterButtonSize } from '../../../../../modals.constants';
import { completeQaQcModalAtoms } from '../../../complete-final-qa-modal.atoms';
import { qaQcJobModalSteps } from '../../../complete-final-qa-modal.constants';
import { CompleteChecklistFooterProps } from './complete-checklist-footer.types';

export const CompleteChecklistFooter = (
  props: CompleteChecklistFooterProps,
) => {
  const { handleCloseModal, job } = props;

  const checklist = useRecoilValue(completeQaQcModalAtoms.checklist);
  const setDuplicatedJobs = useSetRecoilState(
    completeQaQcModalAtoms.duplicatedJobs,
  );
  const isChecklistReviewed = useRecoilValue(
    completeQaQcModalAtoms.isChecklistReviewed,
  );
  const setStep = useSetRecoilState(completeQaQcModalAtoms.step);
  const setUpdateOnClose = useSetRecoilState(
    completeQaQcModalAtoms.updateOnClose,
  );

  const isChecklistFailing = checkAgainstChecklistItems(
    checklist,
    ({ status }) => {
      return status === 'Fail';
    },
  );

  const isHardwiredLight = useMemo(() => {
    const hardwiredLightCategories = [
      productCategories.pendantLight,
      productCategories.wallSconce,
    ];

    return hardwiredLightCategories.some((hardwiredCategory) => {
      return hardwiredCategory === job.product.category;
    });
  }, []);

  const { isLoading: isCompletingJob, onCompleteJob } = useCompleteJob({
    onSuccess: async () => {
      await handleCloseModal({ updateOnClose: true });
    },
  });

  const onComplete = async () => {
    if (isHardwiredLight) {
      setStep(qaQcJobModalSteps.COMPLETE_UL_CHECKLIST);
    } else {
      await onCompleteJob({
        jobId: job.id,
      });
    }
  };

  const { isLoading: isFailingJob, onFailJob } = useFailJob({
    onSuccess: async ({ duplicatedJobs }) => {
      if (!!duplicatedJobs?.length) {
        setDuplicatedJobs(duplicatedJobs);
        setUpdateOnClose(true);
      }

      setStep(qaQcJobModalSteps.JOB_FAILED);
    },
  });

  const onFail = async () => {
    const reason = getFailJobReasonArg(checklist);

    await onFailJob({
      duplicateType: 'Reassemble',
      jobId: job.id,
      reason,
    });
  };

  return (
    <Conditional
      condition={isChecklistFailing}
      Fallback={
        <Button
          disabled={!isChecklistReviewed}
          processing={isCompletingJob}
          size={fullScreenModalFooterButtonSize}
          text={
            isHardwiredLight
              ? 'Confirm Pass'
              : `Confirm ${jobTypeOptions.qc} Completion`
          }
          variant="primary"
          onClick={onComplete}
        />
      }
    >
      <Button
        disabled={!isChecklistReviewed}
        processing={isFailingJob}
        size={fullScreenModalFooterButtonSize}
        text="Fail QC"
        variant="primaryAlert"
        onClick={onFail}
      />
    </Conditional>
  );
};
