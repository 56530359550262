import { useEffect, useMemo, useState } from 'react';
import { useRowSelection } from 'gantri-components';
import { orderBy } from 'lodash';
import {
  FiltersFetchRequestArgs,
  useTableFilters,
} from '../../../../components/common/table/hooks';
import {
  pageName,
  stockSummaryFiltersDefaults,
  stockSummaryPageAtoms,
} from '../../stock-summary.atoms';
import { StockSummaryFilter } from '../../stock-summary-filter';
import {
  FetchStockSummaryArgs,
  FetchStockSummaryResponse,
  StockSummaryRecord,
  StockSummaryTotal,
} from '../../../../api/stocks/routes/fetch-stock-summary/fetch-stock-summary.types';
import {
  stockSummaryModalFiltersDetails,
  stockSummarySortOptions,
} from '../../stock-summary.constants';
import { useFetchStockSummaryQuery } from '../../../../api/stocks/routes/fetch-stock-summary/fetch-stock-summary.query';

type StockSummaryRecordWithId = StockSummaryRecord & {
  id: string;
};

export const useStockSummaryTableData = () => {
  const [filters, setFilters] = useState<FetchStockSummaryArgs>();
  const [footerData, setFooterData] = useState<StockSummaryTotal>();

  const handleSetFilters = (
    args: FiltersFetchRequestArgs<
      FetchStockSummaryArgs,
      keyof StockSummaryRecord
    >,
  ) => {
    const {
      count: _count,
      page: _page,
      search: _search,
      sortingField: _sortingField,
      sortingType: _sortingType,
      ...filters
    } = args;

    setFilters(filters);
  };

  const pageSize = 100;

  const {
    currentPage,
    filtersProps,
    pagingProps,
    records,
    setRecords,
    setTotalCount,
    sortProps,
  } = useTableFilters<
    StockSummaryRecordWithId,
    FetchStockSummaryArgs,
    keyof StockSummaryRecord
  >({
    fetchRequest: handleSetFilters,
    filtersContent: <StockSummaryFilter />,
    modalFiltersDetails: stockSummaryModalFiltersDetails,
    pageName,
    pageSize,
    sortByFilterDetails: {
      atom: stockSummaryPageAtoms.sortBy,
      defaultValue: stockSummaryFiltersDefaults.sortBy,
    },
    sortOptions: stockSummarySortOptions,
  });

  const { clearSelectedRows } = useRowSelection();
  const { data } = useFetchStockSummaryQuery<
    Omit<FetchStockSummaryResponse, 'stocks'> & {
      stocks: StockSummaryRecordWithId[];
    }
  >({
    enabled: !!filters,
    fetchArgs: filters,
    onSuccess: async () => {
      clearSelectedRows();
    },
    showLoading: true,
    transform: (data) => {
      const updatedStocks =
        data?.stocks?.map((stock) => {
          return {
            ...stock,
            // add sku as ID for selectable rows logic
            id: stock.sku,
          };
        }) || [];

      return { ...data, stocks: updatedStocks };
    },
    transformDependencies: [],
  });

  const stocks = data?.stocks || [];

  const sortedData = useMemo(() => {
    return orderBy(
      stocks,
      [sortProps.current.sortingField],
      [sortProps.current.sortingType.toLowerCase() as 'asc' | 'desc'],
    );
  }, [sortProps.current, stocks]);

  useEffect(() => {
    const endIndex = currentPage * pageSize;
    const startIndex = endIndex - pageSize;
    const pagedRecords = sortedData.slice(startIndex, endIndex);

    setRecords(pagedRecords);
  }, [currentPage, sortedData]);

  useEffect(() => {
    setFooterData(data?.total);
    setTotalCount(stocks.length);
  }, [data?.total, stocks.length]);

  return {
    filtersProps,
    footerData,
    pagingProps,
    records,
    sortProps,
  };
};
