import { Button, Conditional, Grid, Icon, TextField } from 'gantri-components';
import { debounce } from 'lodash';
import { useEffect } from 'react';
import { AddFailDetailsProps } from './add-fail-details.types';

export const AddFailDetails = (props: AddFailDetailsProps) => {
  const { checklistItem, onItemChange, reason } = props;
  const {
    details,
    failInputProps,
    hideDetailsOnFail,
    passInputProps,
    requireDetailsOnFail,
    showAddDetails,
    status,
  } = checklistItem;

  useEffect(() => {
    if (status === 'Pass' && (showAddDetails || !!details)) {
      onItemChange(reason, {
        ...checklistItem,
        details: '',
        showAddDetails: false,
      });
    } else if (status !== 'Pass' && hideDetailsOnFail) {
      onItemChange(reason, {
        ...checklistItem,
        details: '',
        showAddDetails: false,
      });
    }
  }, [status]);

  return (
    <>
      <Conditional condition={status === 'Pass' && !!passInputProps}>
        <TextField
          autoFocus
          {...passInputProps}
          errorMessage={passInputProps?.getErrorMessage?.(details)}
          value={details}
          onTextChange={debounce((value: string) => {
            const trimmedValue = value.trim();

            onItemChange(reason, {
              ...checklistItem,
              details: trimmedValue,
            });
          }, 300)}
        />
      </Conditional>
      <Conditional
        condition={status && status !== 'Pass' && !hideDetailsOnFail}
      >
        <Conditional
          condition={showAddDetails || requireDetailsOnFail || !!details}
          Fallback={
            <Button
              icon={<Icon color="link" name="ui-control:plus" />}
              justifySelf="flex-start"
              text="Comment"
              variant="secondary"
              onClick={() => {
                onItemChange(reason, {
                  ...checklistItem,
                  showAddDetails: true,
                });
              }}
            />
          }
        >
          <Grid columns="1fr max-content" gap="0">
            <TextField
              autoFocus
              placeholder="Add details about the failure..."
              {...failInputProps}
              errorMessage={failInputProps?.getErrorMessage?.(details)}
              required={requireDetailsOnFail}
              value={details}
              onTextChange={debounce((value: string) => {
                const trimmedValue = value.trim();

                onItemChange(reason, {
                  ...checklistItem,
                  details: trimmedValue,
                });
              }, 300)}
            />
            <Conditional condition={!requireDetailsOnFail}>
              <Button
                icon={<Icon name="actions:trash_can_24" size="2.4rem" />}
                variant="ghost"
                onClick={() => {
                  onItemChange(reason, {
                    ...checklistItem,
                    details: '',
                    showAddDetails: false,
                  });
                }}
              />
            </Conditional>
          </Grid>
        </Conditional>
      </Conditional>
    </>
  );
};
