import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useNotification } from '../../../../../../../../../../../hooks/useNotification';
import { getFailJobReasonArg } from '../../../../../../../helpers/get-fail-job-reason-arg';
import { getFailingReasonsFromChecklist } from '../../../../../../../helpers/get-failing-reasons-from-checklist';
import { PartsDataArg } from '../../part-checklist-footer.types';
import { completeAssemblyModalAtoms } from '../../../../../complete-assembly-modal.atoms';
import { useFailJob } from '../../../../../../../../../../../api/jobs/routes';

export const useOnFail = () => {
  const setUpdateOnClose = useSetRecoilState(
    completeAssemblyModalAtoms.updateOnClose,
  );
  const setStep = useSetRecoilState(completeAssemblyModalAtoms.step);
  const setDuplicatedJobs = useSetRecoilState(
    completeAssemblyModalAtoms.duplicatedJobs,
  );
  const setExceededCycleTime = useSetRecoilState(
    completeAssemblyModalAtoms.exceededCycleTime,
  );
  const setNewParts = useSetRecoilState(completeAssemblyModalAtoms.newParts);
  const job = useRecoilValue(completeAssemblyModalAtoms.job);
  const jobChecklist = useRecoilValue(completeAssemblyModalAtoms.jobChecklist);
  const partChecklist = useRecoilValue(
    completeAssemblyModalAtoms.partChecklist,
  );

  const { notifyError } = useNotification();

  const { isLoading, onFailJob } = useFailJob({
    onSuccess: async ({ duplicatedJobs, exceededCycleTime, newParts }) => {
      if (!!duplicatedJobs?.length) {
        setDuplicatedJobs(duplicatedJobs);
        setUpdateOnClose(true);
      }

      if (!!newParts?.length) {
        setNewParts(newParts);
      }

      setExceededCycleTime(exceededCycleTime);

      setStep('ASSEMBLY_COMPLETED_FAILED');
    },
  });

  const failingPartIds = getFailingReasonsFromChecklist(partChecklist);

  const onFail = async () => {
    try {
      const partsData: PartsDataArg = failingPartIds.map((id) => {
        const partData: PartsDataArg[number] = {
          details: partChecklist[id].details,
          id,
          type:
            partChecklist[id].status === 'Re-finish'
              ? 'Finish_Complete'
              : 'Restart',
        };

        return partData;
      });

      const reason = getFailJobReasonArg(jobChecklist);

      await onFailJob({
        jobId: job.id,
        partsData,
        reason,
      });
    } catch (error: unknown) {
      notifyError('Unable to fail the job.');
    }
  };

  return { failingPartIds, isLoading, onFail };
};
