import { User } from '../../api/users/users.types';
import { UserRole } from '../../constants/users';
import { UserDetails } from './user.types';

export const convertUserToUserDetails = (
  user: User,
  extraData: Partial<UserDetails> = {},
): UserDetails => {
  const { account, activity, credits, referrals, shop } = user;

  return {
    ...extraData,
    accountCreationDetails: account.creation || {},
    accountType: account.type as UserRole,
    credits,
    currentUserId: account.userId,
    email: account.email,
    facebookConnected: account.facebook,
    firstName: account.firstName,
    googleConnected: account.google,
    howHeardAboutUs: account.howHeardAboutUs || [],
    isActive: account.active,
    lastName: account.lastName,
    notes: account.notes || '',
    referrals,
    resaleCertificate: account.resaleCertificate || '',
    shop,
    subscriber: account.subscriber,
    tradeStatus: account.tradeStatus,
    userActivity: activity,
    userId: account.userId,
    userName:
      account.firstName && account.lastName
        ? `${account.firstName} ${account.lastName}`
        : account.firstName && !account.lastName
        ? account.firstName
        : account.email,
  };
};
