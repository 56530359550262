import {
  FailedReason,
  UlChecklistFailedReason,
} from '../../../../../../api/jobs/routes/get-fail-reasons-list/get-fail-reasons-list.types';
import { Label } from '../../../../../../components/label';
import { Checklist } from './job-checklist.types';

export const ulChecklistMaxValues = {
  acw_hi_pot: 20,
  ir: 50,
} as const satisfies Partial<Record<UlChecklistFailedReason, number>>;

export const ulChecklistUnits = {
  acw_hi_pot: 'mA',
  ir: 'GΩ',
} as const satisfies Partial<Record<UlChecklistFailedReason, string>>;

// Extracted to more easily maintain as the UL measurement checklist has unique logic.
const ulMeasurementChecklistDefaults: Checklist<UlChecklistFailedReason> = {
  acw_hi_pot: {
    description: '',
    details: '',
    hideDetailsOnFail: true,
    hideItemNumber: true,
    label: 'ACW (Hi-Pot)',
    passInputProps: {
      getErrorMessage: (value) => {
        const numValue = Number(value || 0);
        const min = 1;
        const max = ulChecklistMaxValues.acw_hi_pot;
        const isBelowMin = value && numValue < min;
        const isBeyondMax = numValue >= max;

        if (isBeyondMax) {
          return `≥ ${max} ${ulChecklistUnits.acw_hi_pot} is a failing value, please rerun the test`;
        }

        if (isBelowMin) {
          return `Value cannot be below ${min}`;
        }

        return undefined;
      },
      minValue: 0,
      placeholder: 'Input measurement',
      required: true,
      rightIcon: <Label color="t2" text={ulChecklistUnits.acw_hi_pot} />,
      type: 'number',
    },
    requireDetailsOnFail: false,
    showAddDetails: false,
    status: 'Pass',
  },
  ir: {
    description: '',
    details: '',
    failInputProps: {
      getErrorMessage: (value) => {
        const numValue = Number(value || 0);
        const min = 1;
        const max = ulChecklistMaxValues.ir;
        const isBelowMin = value && numValue < min;
        const isBeyondMax = numValue > max;

        if (isBeyondMax) {
          return `>${max} ${ulChecklistUnits.ir} is a passing value, please rerun the test `;
        }

        if (isBelowMin) {
          return `Value cannot be below ${min}`;
        }

        return undefined;
      },
      minValue: 0,
      placeholder: 'Input measurement',
      rightIcon: <Label color="t2" text={ulChecklistUnits.ir} />,
      type: 'number',
    },
    hideItemNumber: true,
    label: 'IR',
    requireDetailsOnFail: true,
    status: 'Pass',
  },
  ul_measurement: {
    description: '',
    details: '',
    label: 'UL Measurement',
    requireDetailsOnFail: true,
    status: 'Pass',
  },
};

export const checklistDefaults: Checklist<FailedReason> = {
  ...ulMeasurementChecklistDefaults,
  bad_finish: {
    description:
      "Paint Surface isn't smooth and uniform: blemishes, cracks, or scuffs present",
    details: '',
    exampleImgSrc:
      'https://res.cloudinary.com/gantri/image/upload/v1653324248/static-assets/bad_finish_FinalQA3_qwyvrb.png',
    label: 'Bad Finish',
    showAddDetails: false,
    status: 'Pass',
    thumbnailSrc:
      'https://res.cloudinary.com/gantri/image/upload/v1653056230/static-assets/diagram_spray_over_2x_dmcbdt.png',
  },
  bad_fit: {
    description:
      "Parts don't interface correctly: assembly tolerances too tight or too loose",
    details: '',
    exampleImgSrc:
      'https://res.cloudinary.com/gantri/image/upload/v1653324244/static-assets/bad_fit_FinalQA_lytf5l.jpg',
    label: 'Loose Part or Bad Fit',
    showAddDetails: false,
    status: 'Pass',
    thumbnailSrc:
      'https://res.cloudinary.com/gantri/image/upload/v1653325180/static-assets/diagram_part_loose_2x_juemez.png',
  },
  bad_gcode: {
    description: 'g-code has errors',
    details: '',
    exampleImgSrc: '',
    label: 'Bad G-code',
    showAddDetails: false,
    status: 'Pass',
    thumbnailSrc:
      'https://res.cloudinary.com/gantri/image/upload/v1667326472/static-assets/diagram_bad_g-code_2x_tlyggd.png',
  },
  banding: {
    description: 'Bands throughout print that are equally spaced by 4 mm',
    details: '',
    exampleImgSrc:
      'https://res.cloudinary.com/gantri/image/upload/v1679523857/static-assets/Banding_translucent.jpg',
    label: 'Banding',
    showAddDetails: false,
    status: 'Pass',
    thumbnailSrc:
      'https://res.cloudinary.com/gantri/image/upload/v1646430146/static-assets/banding_nuqkch.png',
  },
  base_deformed: {
    description:
      'Part geometry at the base is deformed e.g. raft was lifting during print and threads are squished',
    details: '',
    exampleImgSrc:
      'https://res.cloudinary.com/gantri/image/upload/v1653319691/static-assets/base_deformed_PrintQA_ivog0y.jpg',
    label: 'Base Deformed',
    showAddDetails: false,
    status: 'Pass',
    thumbnailSrc:
      'https://res.cloudinary.com/gantri/image/upload/v1652475741/static-assets/diagram_base_deformed_2x_vf2rau.png',
  },
  bl_touch_damaged: {
    description: 'BL touch is bent, broken off or the pin is bent',
    details: '',
    exampleImgSrc:
      'https://res.cloudinary.com/gantri/image/upload/v1653317696/static-assets/bl_touch_damaged_Printcompletion_gtauj1.jpg',
    label: 'BL Touch Damaged',
    showAddDetails: false,
    status: 'Pass',
    thumbnailSrc:
      'https://res.cloudinary.com/gantri/image/upload/v1646430146/static-assets/bl_touch_damaged_e9wpyu.png',
  },
  blobs_of_material_on_print: {
    description: 'Large material blobs in or around print',
    details: '',
    exampleImgSrc:
      'https://res.cloudinary.com/gantri/image/upload/v1653317696/static-assets/blobs_of_material_on_print_Printcompletion_bb9lyy.jpg',
    label: 'Blobs of Material on Print',
    showAddDetails: false,
    status: 'Pass',
    thumbnailSrc:
      'https://res.cloudinary.com/gantri/image/upload/v1646430146/static-assets/blobs_of_material_on_print_hx73rr.png',
  },
  blow_out: {
    description: 'Excessive filament build up around nozzle and fan shroud',
    details: '',
    exampleImgSrc:
      'https://res.cloudinary.com/gantri/image/upload/v1653317699/static-assets/blow_out_Printcompletion_n0hcsy.jpg',
    label: 'Blow Out',
    showAddDetails: false,
    status: 'Pass',
    thumbnailSrc:
      'https://res.cloudinary.com/gantri/image/upload/v1646430146/static-assets/blow_out_dzqxfh.png',
  },
  bowden_pop_out: {
    description:
      'The Bowden tube has popped out of the coupler on the hotend or the extruder',
    details: '',
    exampleImgSrc:
      'https://res.cloudinary.com/gantri/image/upload/v1653317697/static-assets/bowden_pop_out_Printcompletion_l3xsx4.jpg',
    label: 'Bowden Pop Out',
    showAddDetails: false,
    status: 'Pass',
    thumbnailSrc:
      'https://res.cloudinary.com/gantri/image/upload/v1646430146/static-assets/bowden_pop_out_osh98c.png',
  },
  bulb_issue: {
    description: 'Flickering bulb or non-smooth dimming throughout bulb range',
    details: '',
    exampleImgSrc:
      'https://res.cloudinary.com/gantri/image/upload/v1646427278/static-assets/part-large_f1q2qr.png',
    label: 'Bulb Issue',
    showAddDetails: false,
    status: 'Pass',
    thumbnailSrc:
      'https://res.cloudinary.com/gantri/image/upload/v1653325133/static-assets/diagram_bulb_issue_2x_nhfhpv.png',
  },
  bumps_or_zseams: {
    description: 'Consistent over pronounced blobs and seams',
    details: '',
    exampleImgSrc: '',
    label: 'Bumps or Zseams',
    showAddDetails: false,
    status: 'Pass',
    thumbnailSrc: '',
  },
  burn_or_debris: {
    description: 'Dark brown, Black, or Grey Blemishes',
    details: '',
    exampleImgSrc: '',
    label: 'Burns or Debris',
    showAddDetails: false,
    status: 'Pass',
    thumbnailSrc: '',
  },
  burns: {
    description: 'Orange or dark brown blemishes',
    details: '',
    exampleImgSrc:
      'https://res.cloudinary.com/gantri/image/upload/v1653319691/static-assets/burns_PrintQA_i5k1tc.jpg',
    label: 'Burns Longer than 2mm',
    showAddDetails: false,
    status: 'Pass',
    thumbnailSrc:
      'https://res.cloudinary.com/gantri/image/upload/v1646430192/static-assets/burns_tjoqei.png',
  },
  carriage_loose_or_bent: {
    description: 'Carriage has play or is bent',
    details: '',
    exampleImgSrc:
      'https://res.cloudinary.com/gantri/video/upload/v1653317698/static-assets/carriage_loose_or_bent_Printcompletion_dhrucq.mp4',
    label: 'Carriage Loose or Bent',
    showAddDetails: false,
    status: 'Pass',
    thumbnailSrc:
      'https://res.cloudinary.com/gantri/image/upload/v1646430146/static-assets/carriage_loose_or_bent_cwsaoc.png',
  },
  color_bleed: {
    description:
      'Customer-facing areas of paint coating where Primer or PLA is still visible',
    details: '',
    exampleImgSrc: '',
    label: 'Color Bleed',
    showAddDetails: false,
    status: 'Pass',
    thumbnailSrc: '',
  },
  cord_or_switch_issue: {
    description:
      'Quality issue with cord or switch: broken slider, stripped or scuffed cord etc',
    details: '',
    exampleImgSrc:
      'https://res.cloudinary.com/gantri/image/upload/v1646427278/static-assets/part-large_f1q2qr.png',
    label: 'Cord or Switch Issue',
    showAddDetails: false,
    status: 'Pass',
    thumbnailSrc:
      'https://res.cloudinary.com/gantri/image/upload/v1653325137/static-assets/diagram_cord_issue_2x_z9msyz.png',
  },
  cracking: {
    description: 'Visible Breaks or splits in paint surface',
    details: '',
    exampleImgSrc:
      'https://res.cloudinary.com/gantri/image/upload/v1653419112/static-assets/cracked_paint_FinishingQA_1_r4jttx.jpg',
    label: 'Cracking',
    showAddDetails: false,
    status: 'Pass',
    thumbnailSrc:
      'https://res.cloudinary.com/gantri/image/upload/v1653056230/static-assets/diagram_cracked_paint_2x_hh8k4b.png',
  },
  damaged_by_assembly: {
    description: 'Stock scratched, scuffed, or broken by assembly',
    details: '',
    exampleImgSrc:
      'https://res.cloudinary.com/gantri/image/upload/v1646427278/static-assets/part-large_f1q2qr.png',
    label: 'Damaged by Assembly',
    showAddDetails: false,
    status: 'Pass',
    thumbnailSrc:
      'https://res.cloudinary.com/gantri/image/upload/v1667326620/static-assets/diagram_damaged_assembly_2x_nhz153.png',
  },
  debris: {
    description: 'Black or grey blemishes',
    details: '',
    exampleImgSrc:
      'https://res.cloudinary.com/gantri/image/upload/v1653319691/static-assets/debris_PrintQA_j7xppi.jpg',
    label: 'Debris Longer than 2mm',
    showAddDetails: false,
    status: 'Pass',
    thumbnailSrc:
      'https://res.cloudinary.com/gantri/image/upload/v1652475739/static-assets/diagram_large_debris_2x_z3rhgn.png',
  },
  delamination: {
    description:
      'Layer(s) separate from surrounding layers, leaving a gap in the print',
    details: '',
    exampleImgSrc:
      'https://res.cloudinary.com/gantri/image/upload/v1679523945/static-assets/Delamination_translucent.jpg',
    label: 'Delamination',
    showAddDetails: false,
    status: 'Pass',
    thumbnailSrc:
      'https://res.cloudinary.com/gantri/image/upload/v1652829742/static-assets/diagram_delamination_2x_swfbqc.png',
  },
  dents: {
    description: 'Void Blemishes in paint surface',
    details: '',
    exampleImgSrc:
      'https://res.cloudinary.com/gantri/image/upload/v1653418959/static-assets/dents_FinishingQA_gfv0lh.jpg',
    label: 'Dents',
    showAddDetails: false,
    status: 'Pass',
    thumbnailSrc:
      'https://res.cloudinary.com/gantri/image/upload/v1653056230/static-assets/diagram_dent_2x_anpmfu.png',
  },
  dislodge_or_warp_from_bed: {
    description:
      'The raft, or print if there is no raft, has come loose or is warping from the bed',
    details: '',
    exampleImgSrc:
      'https://res.cloudinary.com/gantri/image/upload/v1653317696/static-assets/dislodge_or_warp_from_bed_Printcompletion_xb6zxr.jpg',
    label: 'Dislodge or Warp from Bed',
    showAddDetails: false,
    status: 'Pass',
    thumbnailSrc:
      'https://res.cloudinary.com/gantri/image/upload/v1646430147/static-assets/warping_off_bed_xueojg.png',
  },
  dislodge_or_warp_from_raft: {
    description: 'The print has come loose or is warping from the raft',
    details: '',
    exampleImgSrc:
      'https://res.cloudinary.com/gantri/image/upload/v1653317697/static-assets/dislodge_or_warp_from_raft_Printcompletion_wbsely.jpg',
    label: 'Dislodge or Warp from Raft',
    showAddDetails: false,
    status: 'Pass',
    thumbnailSrc:
      'https://res.cloudinary.com/gantri/image/upload/v1646430147/static-assets/warping_off_raft_vamck9.png',
  },
  excess_paint: {
    description:
      'Excessive paint coating leading to drips or paint buildup in part interfaces',
    details: '',
    exampleImgSrc:
      'https://res.cloudinary.com/gantri/image/upload/v1654706862/static-assets/overspray_1_kzspp3.jpg',
    label: 'Excess Paint',
    showAddDetails: false,
    status: 'Pass',
    thumbnailSrc:
      'https://res.cloudinary.com/gantri/image/upload/v1653056230/static-assets/diagram_spray_over_2x_dmcbdt.png',
  },
  excessive_stringing: {
    description: 'Thin filament strings within or between parts',
    details: '',
    exampleImgSrc:
      'https://res.cloudinary.com/gantri/image/upload/v1653317698/static-assets/excessive_stringing_Printcompletion_gdpcai.jpg',
    label: 'Excessive Stringing',
    showAddDetails: false,
    status: 'Pass',
    thumbnailSrc:
      'https://res.cloudinary.com/gantri/image/upload/v1646430146/static-assets/excessive_stringing_rk1v9e.png',
  },
  extrusion_stopped: {
    description: 'No filament coming from nozzle',
    details: '',
    exampleImgSrc:
      'https://res.cloudinary.com/gantri/image/upload/v1653317697/static-assets/extrusion_stopped_Printcompletion_nb7aib.jpg',
    label: 'Extrusion Stopped',
    showAddDetails: false,
    status: 'Pass',
    thumbnailSrc:
      'https://res.cloudinary.com/gantri/image/upload/v1646430146/static-assets/extrusion_stopped_flxupo.png',
  },
  feature_damage: {
    description: 'Feature no longer function properly',
    details: '',
    exampleImgSrc: '',
    label: 'Feature Damage',
    showAddDetails: false,
    status: 'Pass',
    thumbnailSrc:
      'https://res.cloudinary.com/gantri/image/upload/v1664824588/static-assets/diagram_feature_damage_2x_tzuxz4.png',
  },
  gunk: {
    description: 'Raised Blemishes in paint surface',
    details: '',
    exampleImgSrc:
      'https://res.cloudinary.com/gantri/image/upload/v1653418948/static-assets/gunk_FinishingQA_g6deht.jpg',
    label: 'Gunk',
    showAddDetails: false,
    status: 'Pass',
    thumbnailSrc:
      'https://res.cloudinary.com/gantri/image/upload/v1653056230/static-assets/diagram_gunk_2x_jb2tn7.png',
  },
  heat_sink_fan_not_spinning: {
    description: "Heat sink fan isn't spinning",
    details: '',
    exampleImgSrc:
      'https://res.cloudinary.com/gantri/image/upload/v1653317698/static-assets/heat_sink_fan_not_spinning_Printcompletion_ybb5oj.jpg',
    label: 'Heat Sink Fan Not Spinning',
    showAddDetails: false,
    status: 'Pass',
    thumbnailSrc:
      'https://res.cloudinary.com/gantri/image/upload/v1646430146/static-assets/heat_sink_fan_not_spinning_kza0yu.png',
  },
  heating_failed: {
    description: 'Error Message Displayed',
    details: '',
    exampleImgSrc:
      'https://res.cloudinary.com/gantri/image/upload/v1653317699/static-assets/heating_failed_Printcompletion_zgkwq7.jpg',
    label: 'Heating Failed',
    showAddDetails: false,
    status: 'Pass',
    thumbnailSrc:
      'https://res.cloudinary.com/gantri/image/upload/v1646430146/static-assets/heating_failed_kclljw.png',
  },
  layer_lines: {
    description:
      'Horizontal defects that span part way or all the way around a print, of over or under pronounced light diffusion',
    details: '',
    exampleImgSrc:
      'https://res.cloudinary.com/gantri/image/upload/v1653319691/static-assets/layer_lines_PrintQA_majcjm.jpg',
    label: 'Layer Lines',
    showAddDetails: false,
    status: 'Pass',
    thumbnailSrc:
      'https://res.cloudinary.com/gantri/image/upload/v1646430192/static-assets/layer_lines_dro38v.png',
  },
  lost_part: {
    description: 'Part is misplaced',
    details: '',
    exampleImgSrc: '',
    label: 'Missing Part',
    showAddDetails: false,
    status: 'Pass',
    thumbnailSrc:
      'https://res.cloudinary.com/gantri/image/upload/v1667326485/static-assets/diagram_lost_part_2x_dj83ga.png',
  },
  machine_stopped_moving: {
    description:
      'Not a clog. The machine simply stopped, for any reason. The nozzle will still be touching the part',
    details: '',
    exampleImgSrc: '',
    label: 'Machine Stopped Moving',
    showAddDetails: false,
    status: 'Pass',
    thumbnailSrc:
      'https://res.cloudinary.com/gantri/image/upload/v1646430147/static-assets/material_ran_out_xkvdqk.png',
  },
  //! THIS HAS BEEN REMOVED FOR NOW AS TUMBLER IS NOT CURRENTLY IN USE, BUT THEY MAY BE RE-INTRODUCED IN THE FUTURE
  // marking_scuffing: {
  //   description: 'Visible marks or scuffs on part surface',
  //   details: '',
  //   exampleImgSrc: '',
  //   label: 'Marking/Scuffing',
  //   showAddDetails: false,
  //   status: 'Pass',
  //   thumbnailSrc:
  //     'https://res.cloudinary.com/gantri/image/upload/v1664824592/static-assets/diagram_marking_scuffing_2x_ejhws5.png',
  // },
  material_ran_out: {
    description: 'Not enough material was available to complete the print',
    details: '',
    exampleImgSrc:
      'https://res.cloudinary.com/gantri/image/upload/v1653317700/static-assets/material_ran_out_Printcompletion_ylhhee.jpg',
    label: 'Material Ran Out',
    showAddDetails: false,
    status: 'Pass',
    thumbnailSrc:
      'https://res.cloudinary.com/gantri/image/upload/v1646430147/static-assets/material_ran_out_xkvdqk.png',
  },

  other_failure_modes: {
    description: 'Describe a failure not listed above...',
    details: '',
    exampleImgSrc: '',
    label: 'Other',
    showAddDetails: false,
    status: 'Pass',
    thumbnailSrc:
      'https://res.cloudinary.com/gantri/image/upload/v1652475738/static-assets/diagram_other_2x_z5mxh5.png',
  },
  over_extrusion: {
    description: 'Consistent over pronounced layers and edges, blobs',
    details: '',
    exampleImgSrc:
      'https://res.cloudinary.com/gantri/image/upload/v1653319691/static-assets/over_extrusion_PrintQA_uccdbx.jpg',
    label: 'Over Extrusion',
    showAddDetails: false,
    status: 'Pass',
    thumbnailSrc:
      'https://res.cloudinary.com/gantri/image/upload/v1646430192/static-assets/over_extrusion_yi5zua.png',
  },
  over_sanding: {
    description:
      'Features excessively rounded or thinned significantly changing geometry',
    details: '',
    exampleImgSrc:
      'https://res.cloudinary.com/gantri/image/upload/v1654706863/static-assets/oversand_nabsdm.jpg',
    label: 'Over Sanding',
    showAddDetails: false,
    status: 'Pass',
    thumbnailSrc:
      'https://res.cloudinary.com/gantri/image/upload/v1653056230/static-assets/diagram_sand_over_2x_prehk8.png',
  },
  overhang_texture: {
    description: 'Rough texture due to overhang',
    details: '',
    exampleImgSrc:
      'https://res.cloudinary.com/gantri/image/upload/v1653319691/static-assets/overhang_texture_PrintQA_jxdhsz.jpg',
    label: 'Overhang Texture',
    showAddDetails: false,
    status: 'Pass',
    thumbnailSrc:
      'https://res.cloudinary.com/gantri/image/upload/v1646430192/static-assets/overhang_texture_hjtnkt.png',
  },
  part_breakage: {
    description: 'Visible Breaks in Structure',
    details: '',
    exampleImgSrc: '',
    label: 'Part Breakage',
    showAddDetails: false,
    status: 'Pass',
    thumbnailSrc:
      'https://res.cloudinary.com/gantri/image/upload/v1664824586/static-assets/diagram_part_breakage_2x_aiqsye.png',
  },
  raft_adhesion: {
    description: 'Raft is completely or partially fused to the part',
    details: '',
    exampleImgSrc:
      'https://res.cloudinary.com/gantri/image/upload/v1653317699/static-assets/raft_adhesion2_Printcompletion_jiu73x.jpg',
    label: 'Raft Adhesion',
    showAddDetails: false,
    status: 'Pass',
    thumbnailSrc:
      'https://res.cloudinary.com/gantri/image/upload/v1646430192/static-assets/raft_texture_uzscpi.png',
  },
  raft_texture: {
    description:
      'Raft did not remove cleanly and leaves a darker layer that can be seen in the light',
    details: '',
    exampleImgSrc:
      'https://res.cloudinary.com/gantri/image/upload/v1653319691/static-assets/raft_texture_PrintQA_mkbb7b.jpg',
    label: 'Raft Texture',
    showAddDetails: false,
    status: 'Pass',
    thumbnailSrc:
      'https://res.cloudinary.com/gantri/image/upload/v1646430192/static-assets/raft_texture_uzscpi.png',
  },
  scuffing: {
    description:
      'Interuptions in surface glossiness or texture manifesting as a scuff or mark',
    details: '',
    exampleImgSrc: '',
    label: 'Scuffing',
    showAddDetails: false,
    status: 'Pass',
    thumbnailSrc: '',
  },
  shadowing: {
    description: 'Darker patches on diffuser surface',
    details: '',
    exampleImgSrc: '',
    label: 'Shadowing',
    showAddDetails: false,
    status: 'Pass',
    thumbnailSrc: '',
  },
  shifting: {
    description: 'Layers shifted in X or Y direction',
    details: '',
    exampleImgSrc:
      'https://res.cloudinary.com/gantri/image/upload/v1653317699/static-assets/shifting_Printcompletion_r7q0l7.jpg',
    label: 'Shifting',
    showAddDetails: false,
    status: 'Pass',
    thumbnailSrc:
      'https://res.cloudinary.com/gantri/image/upload/v1646430146/static-assets/shifting_p1pkj1.png',
  },
  spool_issue: {
    description: 'Spool has fallen off or is tangled',
    details: '',
    exampleImgSrc:
      'https://res.cloudinary.com/gantri/image/upload/v1653317700/static-assets/spool_issue_Printcompletion_bskjcc.jpg',
    label: 'Spool Issue',
    showAddDetails: false,
    status: 'Pass',
    thumbnailSrc:
      'https://res.cloudinary.com/gantri/image/upload/v1646430147/static-assets/material_ran_out_xkvdqk.png',
  },
  stringing: {
    description: 'Thin filament strings on part',
    details: '',
    exampleImgSrc: '',
    label: 'Stringing',
    showAddDetails: false,
    status: 'Pass',
    thumbnailSrc: '',
  },
  support_material_or_stringing: {
    description:
      'left over material from print process not properly removed or cleaned',
    details: '',
    exampleImgSrc:
      'https://res.cloudinary.com/gantri/image/upload/v1653324244/static-assets/support_matterial_or_stringing_FinalQA_ayzxpw.jpg',
    label: 'Support material or Stringing',
    showAddDetails: false,
    status: 'Pass',
    thumbnailSrc:
      'https://res.cloudinary.com/gantri/image/upload/v1646430146/static-assets/excessive_stringing_rk1v9e.png',
  },
  thermal_runaway: {
    description: 'Error Message Displayed',
    details: '',
    exampleImgSrc:
      'https://res.cloudinary.com/gantri/image/upload/v1653317700/static-assets/thermal_runaway_Printcompletion_sainhc.jpg',
    label: 'Thermal Runaway',
    showAddDetails: false,
    status: 'Pass',
    thumbnailSrc:
      'https://res.cloudinary.com/gantri/image/upload/v1646430147/static-assets/thermal_runaway_p7jpfk.png',
  },
  thermistor_error: {
    description: 'Error Message Displayed',
    details: '',
    exampleImgSrc:
      'https://res.cloudinary.com/gantri/image/upload/v1653317701/static-assets/thermistor_error_Printcompletion_vuiwe3.jpg',
    label: 'Thermistor Error',
    showAddDetails: false,
    status: 'Pass',
    thumbnailSrc:
      'https://res.cloudinary.com/gantri/image/upload/v1646430147/static-assets/thermistor_error_umxmk5.png',
  },
  thick_lower_line: {
    description: 'Ring at base of diffuser',
    details: '',
    exampleImgSrc:
      'https://res.cloudinary.com/gantri/image/upload/v1661375794/static-assets/Lower_Thick_Line_o7fk5d.png',
    label: 'Thick Lower Line',
    showAddDetails: false,
    status: 'Pass',
    thumbnailSrc:
      'https://res.cloudinary.com/gantri/image/upload/v1661375868/static-assets/diagram_lower_thick_line_2x_gbnfhh.png',
  },
  top_build_up: {
    description: 'Raised Bump at top of diffuser',
    details: '',
    exampleImgSrc:
      'https://res.cloudinary.com/gantri/image/upload/v1661375794/static-assets/Top_buildup_nmovup.png',
    label: 'Top Build Up',
    showAddDetails: false,
    status: 'Pass',
    thumbnailSrc:
      'https://res.cloudinary.com/gantri/image/upload/v1661375870/static-assets/diagram_top_buildup_2x_vqidye.png',
  },
  under_extrusion: {
    description: 'Thin layers or wall, missing lines or layers',
    details: '',
    exampleImgSrc:
      'https://res.cloudinary.com/gantri/image/upload/v1653319692/static-assets/under_extrusion_PrintQA_hdhtqc.jpg',
    label: 'Under Extrusion',
    showAddDetails: false,
    status: 'Pass',
    thumbnailSrc:
      'https://res.cloudinary.com/gantri/image/upload/v1646430192/static-assets/under_extrusion_ilcmvd.png',
  },
  under_sanding: {
    description:
      'Print surface texture and surface variability visible through painted surface ',
    details: '',
    exampleImgSrc:
      'https://res.cloudinary.com/gantri/image/upload/v1654706859/static-assets/undersand_msgise.jpg',
    label: 'Under Sanding',
    showAddDetails: false,
    status: 'Pass',
    thumbnailSrc:
      'https://res.cloudinary.com/gantri/image/upload/v1653056231/static-assets/diagram_sand_under_2x_ft8yag.png',
  },
  under_spray: {
    description:
      'Customer-facing areas of paint coating where Primer or PLA is still visible',
    details: '',
    exampleImgSrc:
      'https://res.cloudinary.com/gantri/image/upload/v1654706860/static-assets/under_spray_nwnu9a.jpg',
    label: 'Under Spray',
    showAddDetails: false,
    status: 'Pass',
    thumbnailSrc:
      'https://res.cloudinary.com/gantri/image/upload/v1653056231/static-assets/diagram_spray_under_2x_r9kp4u.png',
  },
  warping: {
    description:
      'Part geometry at the base is deformed e.g. raft was lifting during print and threads are squished',
    details: '',
    exampleImgSrc: '',
    label: 'Warping',
    showAddDetails: false,
    status: 'Pass',
    thumbnailSrc: '',
  },
  warping_off_bed: {
    description:
      'Raft, or print if there is no raft, has partially lifted from the bed (usually a corner)',
    details: '',
    exampleImgSrc:
      'https://res.cloudinary.com/gantri/image/upload/v1653317696/static-assets/dislodge_or_warp_from_bed_Printcompletion_xb6zxr.jpg',
    label: 'Warping Off Bed',
    showAddDetails: false,
    status: 'Pass',
    thumbnailSrc:
      'https://res.cloudinary.com/gantri/image/upload/v1646430147/static-assets/warping_off_bed_xueojg.png',
  },
  warping_off_raft: {
    description:
      'If there is a raft, raft is stuck to bed but print is partially lifting from the raft',
    details: '',
    exampleImgSrc:
      'https://res.cloudinary.com/gantri/image/upload/v1653317697/static-assets/dislodge_or_warp_from_raft_Printcompletion_wbsely.jpg',
    label: 'Warping Off Raft',
    showAddDetails: false,
    status: 'Pass',
    thumbnailSrc:
      'https://res.cloudinary.com/gantri/image/upload/v1646430147/static-assets/warping_off_raft_vamck9.png',
  },
  wrong_assembly: {
    description: 'Stock not assembled correctly',
    details: '',
    exampleImgSrc:
      'https://res.cloudinary.com/gantri/image/upload/v1646427278/static-assets/part-large_f1q2qr.png',
    label: 'Wrong Assembly',
    showAddDetails: false,
    status: 'Pass',
    thumbnailSrc:
      'https://res.cloudinary.com/gantri/image/upload/v1646430146/static-assets/do_you_have_the_wrong_print_vmhyq4.png',
  },
  wrong_part: {
    description: 'Part or parts not available to complete assembly',
    details: '',
    exampleImgSrc: '',
    label: 'Wrong Part',
    showAddDetails: false,
    status: 'Pass',
    thumbnailSrc:
      'https://res.cloudinary.com/gantri/image/upload/v1646430146/static-assets/do_you_have_the_wrong_print_vmhyq4.png',
  },
  wrong_print: {
    description: 'Wrong print was started',
    details: '',
    exampleImgSrc: '',
    label: 'Wrong Print',
    showAddDetails: false,
    status: 'Pass',
    thumbnailSrc:
      'https://res.cloudinary.com/gantri/image/upload/v1646430146/static-assets/do_you_have_the_wrong_print_vmhyq4.png',
  },
};
