import { Typography, useConfirmationModal } from 'gantri-components';
import { UserDetails } from '../user.types';
import { useInvalidateFetchPaginatedUsersCache } from '../../../api/users/routes';
import { usersApi } from '../../../api';
import { useNotification } from '../../../hooks/useNotification';

interface Props {
  currentUser: UserDetails;
  isActive: boolean;
  updateUser: (data) => void;
  userId: number;
}

export const useToggleActiveAccount = (props: Props) => {
  const { currentUser, isActive, updateUser, userId } = props;
  const { notify, notifyAxiosError } = useNotification();

  const { invalidateFetchPaginatedUsersCache } =
    useInvalidateFetchPaginatedUsersCache();

  const onConfirm = async () => {
    try {
      const active = !isActive;
      const { data } = await usersApi.toggleActiveAccount({
        active,
        userId,
      });

      if (data) {
        updateUser({ ...currentUser, isActive: data.account.active });
        await invalidateFetchPaginatedUsersCache();
        hideModal();
      }

      const verb = active ? 'activated' : 'deactivated';
      const message = `Account has been ${verb}`;

      notify(message);
    } catch (error: unknown) {
      notifyAxiosError({
        error,
        fallbackMessage: 'Unable to update user status.',
      });
    }
  };

  const verb = isActive ? 'deactivating' : 'activating';

  const [showModal, hideModal] = useConfirmationModal(
    {
      confirmButtonText: 'Confirm',
      confirmButtonVariant: 'primary',
      content: (
        <Typography
          style={{ textAlign: 'center' }}
          text={`Are you sure about ${verb} this account?`}
        />
      ),
      headerText: 'Activate/Deactivate user',
      onClose: () => {
        hideModal();
      },
      onConfirm,
      width: { lg: '42rem', md: '100%' },
    },
    [userId, isActive],
  );

  return showModal;
};
