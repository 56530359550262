import {
  Cell,
  Conditional,
  Grid,
  Icon,
  Stack,
  Tooltip,
  Typography,
} from 'gantri-components';
import { useMemo } from 'react';
import { ChecklistItem } from '../../../../job-checklist.types';
import { FailureDetails } from '../../../failure-details';
import { StatusToggle } from '../../../status-toggle';
import { AddFailDetails } from '../add-fail-details';
import { SurfaceSliders } from '../surface-sliders';
import { StyledReasonLabel } from './advanced-checklist-reason.styles';
import { AdvancedChecklistReasonProps } from './advanced-checklist-reason.types';
import { BinaryImage } from './components/binary-image';
import { SeverityImages } from './components/severity-images';
import { useThumbnailZoomModal } from './use-thumbnail-zoom-modal';
import { AdvancedChecklistThumbnail } from './components/advanced-checklist-thumbnail';

export const AdvancedChecklistReason = (
  props: AdvancedChecklistReasonProps,
) => {
  const {
    buttonToggleWidth,
    checklistItem,
    material,
    onItemChange,
    reason,
    reasonNum,
    statuses,
  } = props;

  const { exampleImgSrc, hideItemNumber, label, showThumbnail, slider, tool } =
    checklistItem;

  const isOtherReason = reason === 'other_failure_modes';
  const isBinaryImage = tool === 'binary-image';
  const isSeverityImages = tool === 'severity-images';
  const isSlider = !!slider;

  const updateChecklistItem = (updatedChecklistItem: ChecklistItem) => {
    return onItemChange(reason, updatedChecklistItem);
  };

  const [showZoomModal] = useThumbnailZoomModal({
    heading: label,
    src: exampleImgSrc,
  });

  return useMemo(() => {
    return (
      <Grid
        columns={showThumbnail ? '1fr 10rem' : '1fr'}
        data-cy-checklist-reason={reason}
      >
        <Stack gap="1.2rem" height="unset">
          <Stack gap="0.4rem" height="unset">
            <Conditional condition={!hideItemNumber}>
              <Typography
                text={
                  String(reasonNum).length === 1 ? `0${reasonNum}` : reasonNum
                }
              />
            </Conditional>
            <StyledReasonLabel
              data-cy-checklist-label={label}
              icon={
                <Conditional
                  condition={
                    !showThumbnail && exampleImgSrc && !tool && !isSlider
                  }
                >
                  <Tooltip description="Show example image" position="top">
                    <Icon
                      color="t2"
                      name="media:photo"
                      onClick={showZoomModal}
                    />
                  </Tooltip>
                </Conditional>
              }
              iconPosition="right"
              text={label}
              variant="h5"
            />
          </Stack>
          <Conditional
            condition={isOtherReason}
            Fallback={
              <>
                <Conditional
                  condition={!!tool}
                  Fallback={
                    <StatusToggle
                      buttonToggleWidth={buttonToggleWidth}
                      option={checklistItem}
                      reason={reason}
                      statuses={statuses}
                      onItemChange={onItemChange}
                    />
                  }
                >
                  <Conditional condition={isBinaryImage}>
                    <BinaryImage checklistItem={checklistItem} />
                    <StatusToggle
                      buttonToggleWidth={buttonToggleWidth}
                      option={checklistItem}
                      reason={reason}
                      statuses={statuses}
                      onItemChange={onItemChange}
                    />
                  </Conditional>
                  <Conditional condition={isSlider}>
                    <SurfaceSliders
                      checklistItem={checklistItem}
                      material={material}
                      updateChecklistItem={updateChecklistItem}
                    />
                  </Conditional>
                  <Conditional condition={isSeverityImages}>
                    <SeverityImages
                      checklistItem={checklistItem}
                      material={material}
                      updateChecklistItem={updateChecklistItem}
                    />
                  </Conditional>
                </Conditional>
                <AddFailDetails
                  checklistItem={checklistItem}
                  reason={reason}
                  onItemChange={onItemChange}
                />
              </>
            }
          >
            <Cell>
              <FailureDetails
                isOtherReason
                option={checklistItem}
                reason={reason}
                onItemChange={onItemChange}
              />
            </Cell>
          </Conditional>
        </Stack>
        <AdvancedChecklistThumbnail checklistItem={checklistItem} />
      </Grid>
    );
  }, [checklistItem]);
};
