import React, { CSSProperties, FC } from 'react';
import { Conditional, Grid, Typography } from 'gantri-components';
import { StatusIcon } from './status-icon';
import { machineStatusDetailsMap } from './general-section.constant';
import { MachineStatus } from '../../../../constants/machines';

export const StatusItem: FC<{
  showDescription?: boolean;
  status: MachineStatus;
  style?: CSSProperties;
}> = ({ showDescription, status, style }) => {
  return (
    <Conditional condition={!!status}>
      <Grid
        alignItems="center"
        columns="1.6rem 1fr"
        gap="0.8rem"
        horizontalPadding={showDescription ? 'x' : 'unset'}
        style={style}
        verticalPadding={showDescription ? '0.8rem' : 'unset'}
      >
        <StatusIcon status={status} />
        <div>
          <Typography color="t1" text={status} />
          {showDescription && (
            <Typography
              color="t2"
              text={machineStatusDetailsMap[status]}
              variant="p3"
            />
          )}
        </div>
      </Grid>
    </Conditional>
  );
};
