import { createRoot } from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';
import '@firebase/storage';
import PubNub from 'pubnub';
import { PubNubProvider } from 'pubnub-react';
import { RecoilRoot } from 'recoil';
import { initializeHotjar } from './helpers/hotjar';
import { routers } from './config/routes';
import reportWebVitals from './reportWebVitals';
import { environment } from './environment';
import { SESSION_IDENTIFIER } from './helpers/auth';
import 'intersection-observer'; // resize-observer-polyfill for in-app-browser

// resize-observer-polyfill for in-app-browser
(async function () {
  if (!window.ResizeObserver) {
    window.ResizeObserver = (await import('resize-observer-polyfill')).default;
  }
})();

const pubnub = new PubNub({
  ...environment.PUBNUB_CONFIG,
  userId: SESSION_IDENTIFIER,
});

if (environment.IS_PRODUCTION) {
  initializeHotjar();
}

const Core = () => {
  return (
    <PubNubProvider client={pubnub}>
      <RecoilRoot>
        <RouterProvider router={routers} />
      </RecoilRoot>
    </PubNubProvider>
  );
};

const container = document.getElementById('root');
const root = createRoot(container);

root.render(<Core />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
