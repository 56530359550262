import { Button } from 'gantri-components';
import { useRecoilValue } from 'recoil';
import { fullScreenModalFooterButtonSize } from '../../../../modals.constants';
import { completeQaQcModalAtoms } from '../../complete-final-qa-modal.atoms';
import { qaQcJobModalSteps } from '../../complete-final-qa-modal.constants';
import { CompleteChecklistFooter } from '../step-complete-checklist/complete-checklist-footer';
import { CompleteUlChecklistFooter } from '../step-complete-ul-checklist/complete-ul-checklist-footer';
import { ConfirmStartFooter } from '../step-confirm-start/confirm-start-footer';
import { QaQcJobFooterProps } from './qa-qc-job-footer.types';

export const QaQcJobFooter = (props: QaQcJobFooterProps) => {
  const { handleCloseModal, job } = props;

  const step = useRecoilValue(completeQaQcModalAtoms.step);

  switch (step) {
    case qaQcJobModalSteps.CONFIRM_START:
      return (
        <ConfirmStartFooter handleCloseModal={handleCloseModal} job={job} />
      );

    case qaQcJobModalSteps.COMPLETE_CHECKLIST:
      return (
        <CompleteChecklistFooter
          handleCloseModal={handleCloseModal}
          job={job}
        />
      );

    case qaQcJobModalSteps.COMPLETE_UL_CHECKLIST:
      return (
        <CompleteUlChecklistFooter
          handleCloseModal={handleCloseModal}
          job={job}
        />
      );

    case qaQcJobModalSteps.JOB_FAILED:
      return (
        <Button
          size={fullScreenModalFooterButtonSize}
          text="Done"
          onClick={async () => {
            await handleCloseModal();
          }}
        />
      );

    default:
      return null;
  }
};
