import { useGetInvalidateQueryCache } from '../../../../hooks/use-fetch/use-query-fetch';
import {
  GetPaginatedUsersArgs,
  GetPaginatedUsersResponse,
} from './get-paginated-users.types';
import { fetchPaginatedUsers } from './get-paginated-users';
import { getGenericQueryObj } from '../../../../hooks/use-generic-fetch-query/helpers/get-generic-query-obj';
import { GenericFetchProps } from '../../../../hooks/use-generic-fetch-query/use-generic-fetch-query.types';
import { useGenericFetchQuery } from '../../../../hooks/use-generic-fetch-query';

const fetchPaginatedUsersQueriesObj = getGenericQueryObj({
  groupName: 'fetch-paginated-users',
  query: fetchPaginatedUsers,
});

export const useFetchPaginatedUsers = <
  TransformedData = GetPaginatedUsersResponse,
>(
  props?: GenericFetchProps<
    GetPaginatedUsersArgs,
    GetPaginatedUsersResponse,
    TransformedData
  >,
) => {
  return useGenericFetchQuery({
    fallbackErrorMessage: 'Unable to fetch paginatedUsers.',
    queryObj: fetchPaginatedUsersQueriesObj,
    ...props,
  });
};

export const useInvalidateFetchPaginatedUsersCache = () => {
  const invalidateFetchPaginatedUsersCache = useGetInvalidateQueryCache(
    fetchPaginatedUsersQueriesObj,
  );

  return { invalidateFetchPaginatedUsersCache };
};
